import React, { useEffect, useState } from "react";
import AdminUserDashboard from "@/widgets/components/pagecomponents/Dashboard/admin";
import SuperAdminUserDashboard from "@/widgets/components/pagecomponents/Dashboard/superadmin";
import CapAdminUserDashboard from "@/widgets/components/pagecomponents/Dashboard/capadmin";
import AccountsUserDashboard from "@/widgets/components/pagecomponents/Dashboard/accounts";
import DispatchUserDashboard from "@/widgets/components/pagecomponents/Dashboard/dispatch";

export function Home() {
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  return (
   <div>
    { userData.roles === 'SUPERADMIN' && (<SuperAdminUserDashboard />)}
    { userData.roles === 'ADMIN' && (<AdminUserDashboard />)}
    { userData.roles === 'CAPADMIN' && (<AdminUserDashboard />)}
    { userData.roles === 'DISPATCH' && (<DispatchUserDashboard />)}
    { userData.roles === 'ACCOUNTS' && (<AccountsUserDashboard />)}
   </div>
  );
}

export default Home;
