import React, { useState } from 'react';
import {
    Card,
    Input,
    Button,
    Typography,
    Spinner,
    Select,
    Option
} from "@material-tailwind/react";
import {
    ArrowPathIcon
} from "@heroicons/react/24/solid";

export function CreateRouteFormComponent({
    formData,
    loading,
    handleInputChange,
    handleSelectChange,
    handleSubmit,
    vehicles,
    clients
}) {
    const [errors, setErrors] = useState({});

    const validateRouteCode = (code) => {
        const regex = /^[A-Za-z0-9]{10}$/;
        return regex.test(code);
    };

    const handleInputChangeWithValidation = (e) => {
        handleInputChange(e);
    };

    return (
        <Card color="transparent" shadow={false}>
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Start Point
                        </Typography>
                        <Input
                            size="lg"
                            name="start_point"
                            value={formData.start_point}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            End Point
                        </Typography>
                        <Input
                            size="lg"
                            name="end_point"
                            type="text"
                            value={formData.end_point}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>  
                    
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Route Code
                        </Typography>
                        <Input
                            size="lg"
                            name="route_code"
                            type="text"
                            value={formData.route_code}
                            onChange={handleInputChangeWithValidation}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                        {/* {errors.route_code && (
                            <Typography variant="small" color="red">
                                {errors.route_code}
                            </Typography>
                        )} */}
                    </div>  
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Default Vehicle
                        </Typography>
                        <Select
                            size="lg"
                            name="vehicle_id"
                            onChange={(value) => handleSelectChange('vehicle_id', value)}
                            required
                        >
                            { vehicles.length && vehicles.map(vehicle => (
                                <Option key={vehicle.id} value={vehicle.id}>
                                    {vehicle.registration}
                                </Option>
                            ))}
                        </Select> 
                    </div> 
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Client
                        </Typography>
                        <Select
                            size="lg"
                            name="agency_id"
                            onChange={(value) => handleSelectChange('agency_id', value)}
                            required
                        >
                            { clients.length && clients.map(client => (
                                <Option key={client.id} value={client.id}>
                                    {client.name}
                                </Option>
                            ))}
                        </Select> 
                    </div> 
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Route Type
                        </Typography>
                        <Select
                            size="lg"
                            name="route_type"
                            onChange={(value) => handleSelectChange('route_type', value)}
                            required
                        >
                            <Option value={"REGULAR"}>
                                Regular route
                            </Option>
                            <Option value={"SECURITY"}>
                                Security route
                            </Option>
                        </Select> 
                    </div> 
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Estimated Route Length
                        </Typography>
                        <Input
                            size="lg"
                            name="estimate_length"
                            type="text"
                            value={formData.estimate_length}
                            onChange={handleInputChangeWithValidation}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />                       
                    </div> 
                    <div className="col-span-1">
                        <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                            {loading ? <Spinner size="small" /> : 'Create Route'}
                        </Button>
                    </div>
                </div>               
            </form>
        </Card>
    );
}

export default CreateRouteFormComponent;
