import React, { useEffect, useState } from "react";
import { Card, CardBody, Typography, Spinner } from "@material-tailwind/react";
import OrgBookings from "@/widgets/components/pagecomponents/clients/orgBookings";
import OrganisationsTable from "@/widgets/components/pagecomponents/clients/organisations";
import OrgPeople from "@/widgets/components/pagecomponents/clients/orgPeople";
import OrgAdmins from "@/widgets/components/pagecomponents/clients/orgAdmins";
import OrgTariffs from "@/widgets/components/pagecomponents/clients/orgTariffs";
import OrgDepartments from "@/widgets/components/pagecomponents/clients/orgDepartments";
import OrganisationManagement from "@/widgets/components/pagecomponents/clients/organisationManagement";
import { fetchOrganisation, fetchSubOrganisation } from "@/apis/clients";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from '@/widgets/alerts/general';
import { useParams, useNavigate } from 'react-router-dom';
import {
  CloudIcon,
  UserGroupIcon,
  Cog8ToothIcon,
  TruckIcon,
  BuildingStorefrontIcon,
  AdjustmentsHorizontalIcon,
  ArrowLeftCircleIcon
} from "@heroicons/react/24/solid";

export function OrganisationDetails() {
  const [organisation, setOrganisation] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [selectedNav, setSelectedNav] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userDetails"))

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const organisations = userData.roles === "CAPADMIN" ? await fetchSubOrganisation(token, id) : await fetchOrganisation(token, id);
      if (organisations) {
        setOrganisations(organisations);
        setOrganisation(organisations.agency);
        setLoading(false);
        
      } else {
        setAlert({
          status: true,
          message: "Unable to fetch client at this time.",
          type: 'error',
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: 'We are having trouble fetching client data. Try again.',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);  

    fetchData();
  }, [id]);

  useEffect(()=>{
    organisation?.agencyType === 'SUPERCAPACCOUNT' ? setSelectedNav('agencies'): setSelectedNav('bookings')
  },[organisation]);

  const handleNavClick = (navItem) => {
    setSelectedNav(navItem);
  };

  return (
    <div className="m-2">
      <div className="mb-4">
        <div class="p-2 text-left">
          <h6 class="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
          {organisation.name}
          </h6>
          <p
            class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
            Client Account Type: {organisation.agencyType === 'SUPERCAPACCOUNT' ? 'Client' : 'Agency'}
          </p>
          <p
            class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
            Routes Type: {organisation.route_type?.toLowerCase() }
          </p>
        </div>
      </div>
      <div className="mt-12 mb-8 flex flex-row gap-6">
        <div className="flex-shrink-0 w-38 bg-gray-100 py-4">
          <div className="flex flex-col gap-2">
          <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg 'bg-white text-gray-700`}
              onClick={() => navigate(-1)}
            >
              <ArrowLeftCircleIcon className="h-5 w-5 mr-2" />
              Back
            </button>
            { organisation.agencyType === 'SUPERCAPACCOUNT' && (
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'agencies' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('agencies')}
            >
              <BuildingStorefrontIcon className="h-5 w-5 mr-2" />
              Agencies/Departments
            </button>  
            )}
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'bookings' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('bookings')}
            >
              <CloudIcon className="h-5 w-5 mr-2" />
              Bookings
            </button>           
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'people' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('people')}
            >
              <UserGroupIcon className="h-5 w-5 mr-2" />
              People
            </button>
            {/* { organisation.agencyType === 'SUPERCAPACCOUNT' && ( */}

            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'admins' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('admins')}
            >
              <UserGroupIcon className="h-5 w-5 mr-2" />
              Admins & Approvers
            </button>
            {/* )} */}
            { organisation.agencyType === 'SUPERCAPACCOUNT' && (
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'tariff' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('tariff')}
            >
              <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2" />
              Tariff
            </button> 
            )}                     
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'settings' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('settings')}
            >
              <Cog8ToothIcon className="h-5 w-5 mr-2" />
              Management
            </button>    
          </div>
          {/* <TripDataSummary stats={stats} /> */}
        </div>
        <div className="flex-1  overflow-scroll">
          <Card>
            <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                {loading ? (
                  <Typography>
                    <Spinner />
                  </Typography>
                ) : (
                  alert.status ? (
                    <div className="w-1/2 mx-auto">
                      <GeneralAlert props={{ ...alert, setAlert }} />
                    </div>
                  ) : (
                    <>
                      {selectedNav === 'bookings' && (
                        <div className="p-4">
                            <OrgBookings id={id} />
                        </div>
                      )}
                      {selectedNav === 'people' && (
                        <div className="p-4">
                          <OrgPeople  id={id} />
                        </div>
                      )}   
                      {selectedNav === 'agencies' && (
                        <div className="p-4">
                            <OrganisationsTable id={id} />
                        </div>
                      )}   
                      {selectedNav === 'departments' && (
                        <div className="p-4">
                          <OrgDepartments  id={id} />
                        </div>
                      )}    
                      {selectedNav === 'admins' && (
                        <div className="p-4">
                          <OrgAdmins  id={id} />
                        </div>
                      )}  

                      {selectedNav === 'tariff' && (
                        <div className="p-4">
                          <OrgTariffs  id={id} />
                        </div>
                      )} 

                      {selectedNav === 'settings' && (
                        <div className="p-4">
                          <OrganisationManagement  formData={organisation} id={id} fetchData={fetchData} />
                        </div>
                      )}                  
                    </>
                  )
                )}
              </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default OrganisationDetails;
