import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { 
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import { MaterialTailwindControllerProvider } from "@/context";
import  { AuthProvider } from "@/context/auth";
import * as Sentry from "@sentry/react";
import "../public/css/tailwind.css";
const VITE_SENTRYDNS = import.meta.env.VITE_SENTRYDNS;

Sentry.init({
  dsn: VITE_SENTRYDNS,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0, 
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <MaterialTailwindControllerProvider>
          <AuthProvider>
            <App />
          </AuthProvider>  
        </MaterialTailwindControllerProvider>
      </ThemeProvider>   
    </BrowserRouter>
  </React.StrictMode>
);
