import React, { useEffect, useState } from "react";
import {
  Spinner,
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import TripsStatisticsComponent from "@/widgets/charts/trips-statistics"
import BookingsStatisticsComponent from "@/widgets/charts/bookings-statistics"
import { fetchSuperadminDashboardData } from "@/apis/dash"

export function SuperAdminUserDashboard() {
  const [loading, setLoading] = useState(false);
  const [dashData, setDashData] = useState({
    "tripsToday": 0,
    "tripsYesterday": 0,
    "tripsLastWeek": {},
    "tripsThisMonth": {},
    "bookingsToday": 0,
    "bookingsYesterday": 0,
    "bookingsLastWeek": {},
    "bookingsThisMonth": {},
    "bookingStatistics":{},
    "driversCount": 0,
    "vehiclesCount": 0,
    "routesCount":0,
    "passengersCount": 0,
    "driverReports": [],
    "bookingsLastYear":[]
  });
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(()=>{
    setLoading(true);
    const token = localStorage.getItem('token');
    const fetchData = async () => {
      try{
        const fetchData = await fetchSuperadminDashboardData(token);
        setDashData(fetchData);
      }catch(error){
        setAlert({ status: true, message: 'Error fetching charges.', type: 'error' });
      } finally{
        setLoading(false);
      }
    }

    fetchData();
  },[]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="mb-4">
        <TripsStatisticsComponent data={dashData} />    
        <BookingsStatisticsComponent data={dashData} />   
      
    </div>
  );
}

export default SuperAdminUserDashboard;
