import React, { useState, useEffect } from 'react';
import { Card, CardBody, Tabs, TabsHeader, Tab, Typography } from "@material-tailwind/react";
import { HomeIcon, AdjustmentsVerticalIcon, Cog6ToothIcon } from "@heroicons/react/24/solid";
import GeneralInformation from '@/widgets/segments/GeneralProfileInfo';
import SecuritySettings from '@/widgets/segments/SecuritySettings';
import ActivityLogs from '@/widgets/segments/UseractivityLogs';
import  { resetPassword, fetchUserLogs } from "@/apis/profile";

export function Profile() {
  const [activeTab, setActiveTab] = useState("general");
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
  
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [logs, setLogs] = useState([]);

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setAlert({ status: false, message: '', type: '' });
    const token = localStorage.getItem('token');
    const result = await resetPassword(token, formData.id, formData.newPassword);
    if (result) {
      setAlert({ status: true, message: 'Password reset successful', type: 'success' });
      setLoading(false);
    } else {
      setAlert({ status: true, message: 'Password reset failed', type: 'error' });
      setLoading(false);
    }
  };



  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });

    const fetchData = async () => {
      try {
          const token = localStorage.getItem('token');
          const logs = await fetchUserLogs(token);
          if (logs) {
            setLogs(logs);
            setLoading(false);
          } else {
              setAlert({
                  status: true,
                  message: "Unable to fetch logs.",
                  type: 'error',
                });
            setLoading(false);
          }
      } catch (error) {
        console.log(error)
          setLoading(false);
          sendSentryAlert(error);
          setAlert({
              status: true,
              message: 'We are having trouble fetching logs.',
              type: 'error',
            });
      }
  };

    fetchData();
  }, []);

  return (
    <>
      <Card className="mx-3 mt-16 mb-6 lg:mx-4 border border-blue-gray-100">
        <CardBody className="p-4">
          <div className="mb-10 flex items-center justify-between flex-wrap gap-6">
            <div className="flex items-center gap-6">              
              <div>
                <Typography variant="h5" color="blue-gray" className="mb-1">
                  {userDetails.username || '---' }
                </Typography>
                <Typography
                  variant="small"
                  className="font-normal text-blue-gray-600"
                >
                  {userDetails.roles || '---'}
                </Typography>
              </div>
            </div>
            <div className="w-96">
              <Tabs value={activeTab}>
                <TabsHeader>
                  <Tab value="general" onClick={() => handleTabChange("general")}>
                    <HomeIcon className="-mt-1 mr-2 inline-block h-5 w-5" />
                    General
                  </Tab>
                  <Tab value="settings" onClick={() => handleTabChange("settings")}>
                    <Cog6ToothIcon className="-mt-0.5 mr-2 inline-block h-5 w-5" />
                    Settings
                  </Tab>
                  <Tab value="logs" onClick={() => handleTabChange("logs")}>
                    <AdjustmentsVerticalIcon className="-mt-1 mr-2 inline-block h-5 w-5" />
                    Logs
                  </Tab>
                </TabsHeader>
              </Tabs>
            </div>
          </div>

          {activeTab === "general" && (
            <GeneralInformation userDetails={userDetails} />
          )}

          {activeTab === "settings" && (
            <SecuritySettings 
              onSubmit={handleSubmit}
              loading={loading}
              alert={alert}
              setAlert={setAlert}
            />
          )}

          {activeTab === "logs" && (
            <ActivityLogs logs={logs }/>
          )}

        </CardBody>
      </Card>
    </>
  );
}

export default Profile;
