import React from 'react';
import {
    Card,
    Input,
    Button,
    Typography,
    Spinner
} from "@material-tailwind/react";

export function CreateFuelChargeFormComponent({
    formData,
    loading,
    handleInputChange,
    handleSubmit,
    handleDisableCharge,
    isUpdate
}) {
    return (
        <Card color="transparent" shadow={false}>
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Lower Limit (Ksh) 
                        </Typography>
                        <Input
                            size="lg"
                            name="lower_limit"
                            type="number"
                            min={0}
                            value={formData.lower_limit}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required={!isUpdate}
                        />
                    </div> 
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Upper Limit (Ksh) 
                        </Typography>
                        <Input
                            size="lg"
                            name="upper_limit"
                            type="number"
                            min={0}
                            value={formData.upper_limit}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required={!isUpdate}
                        />
                    </div>                    
                </div> 
                <div className="">
                    {isUpdate ? (
                        <div className="flex justify-between w-full">
                            <Button className="m-6" fullWidth type="submit" disabled={loading}>
                                {loading ? <Spinner size="small" /> : 'Update Charge'}
                            </Button>
                            {formData.is_active ? (
                                <Button className="m-6" color="red" fullWidth onClick={handleDisableCharge} disabled={loading}>
                                    {loading ? <Spinner size="small" /> : 'Deactivate Charge'}
                                </Button>
                            ) : (
                                <Button className="m-6" color="green" fullWidth onClick={handleDisableCharge} disabled={loading}>
                                    {loading ? <Spinner size="small" /> : 'Activate Charge'}
                                </Button>
                            )}
                        </div>                            
                    ) : (
                        <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                            {loading ? <Spinner size="small" /> : 'Create Charge'}
                        </Button>
                    ) }
                </div>              
            </form>
        </Card>
    );
}

export default CreateFuelChargeFormComponent;
