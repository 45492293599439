import React from "react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
const VehicleTripsComponent = ({ data }) => {
  console.log(data)

  const headers = ["Dispatch Code", "Route Code", "From", "To", "Driver","Passengers Onboard" ,"Status", "Trip Date"];
  const columns = ["dispatch_code", "route_code", "start_point", "end_point", "driver_name","passengers_count", "dispatch_status", "trip_date"];

  return (
    <> 
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={ data?.trip_dispatch || []}
        sortableColumns={[0, 1, 2, ]}
        titleColumnIndex={0}
        searchEnabled={true}
        titleClickable={false}
        settingsColumn={false}
        hideCreateButton = {true}
      />
    </>
  );
};

export default VehicleTripsComponent;
