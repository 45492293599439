import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateFuelChargeFormComponent from '@/widgets/components/pagecomponents/finances/createFuelChartComponent';
import GeneralAlert from '@/widgets/alerts/general';
import  { updateChargeFunc, fetchChargeDetailsFunc }  from "@/apis/maintenances";
import { useNavigate, useParams } from 'react-router-dom';

export function ManageFinanceItem() {
    const [data, setData] = useState({
    });

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '',type: '',});  

    const navigate = useNavigate();
    const { id } = useParams();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSelectChange = (name, value) => {
      setData({ ...data, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '',type: ''  });
        const token = localStorage.getItem('token');
        try {

            const requestData = {
              id:id,
              lower_limit: parseInt(data.lower_limit) || undefined,
              upper_limit: parseInt(data.upper_limit) || undefined,
            };

            console.log("requestData:", requestData);

            const createCharge = await updateChargeFunc(token, requestData);
            if(createCharge){
              setLoading(false);
              navigate(`/dashboard/finance`);
            }else{
              setLoading(false);
              setAlert({
                  status: true,
                  message: 'Failed to update charge.',
                  type: 'error' 
              });
            }
        } catch (error) {
            setLoading(false);
            sendSentryAlert(error);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'Failed to update charge.',
                type: 'error' 
            });
        }
    };
  
    const handleDisableCharge = async (e) => {
      e.preventDefault();
      setLoading(true);
      setAlert({ status: false, message: '',type: ''  });
      const token = localStorage.getItem('token');
      try {
        const requestData = {
          id:id,
          is_active:!data.is_active
        };
          const updateCharge = await updateChargeFunc(token, { ...requestData });
          if(updateCharge){
            setLoading(false);
            navigate(`/dashboard/finance`);
          }else{
            setLoading(false);
            setAlert({
                status: true,
                message: 'Failed to update charge.',
                type: 'error' 
            });
          }
      } catch (error) {
          setLoading(false);
          sendSentryAlert(error);
          setAlert({
              status: true,
              message: error.response?.data?.message || 'Failed to Delete charge.',
              type: 'error' 
          });
      }
  };


    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const data = await fetchChargeDetailsFunc(token, id);
        if (data) {
          setData(data);
          setLoading(false);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch charge at this time.",
            type: 'error',
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'System having trouble fetching charge data.',
          type: 'error',
        });
      }
    };

  useEffect(()=>{
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    fetchData();
  },[id])

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="flex justify-between mb-8 p-6">
          <Typography variant="h6" color="white">
                Manage Charge
            </Typography>
            <div className="flex items-center">
                <button onClick={() => navigate(`/dashboard/finance`)} className="text-white underline">
                    Back
                </button>
            </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
            { alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null }

            <CreateFuelChargeFormComponent
                formData={data}
                loading={loading}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                handleSubmit={handleSubmit}
                isUpdate={true}
                handleDisableCharge={handleDisableCharge}
            />           
          
        </CardBody>
      </Card>
    </div>
  );
}

export default ManageFinanceItem;
