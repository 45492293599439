import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import TripsTable from "@/widgets/components/pagecomponents/trips/tripsTable";
import { fetchTrips } from "@/apis/trips";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from '@/widgets/alerts/general';

export function Trips() {
  const [trips, setTrips] = useState([]);
  const [tripStats, setTripStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            const data = await fetchTrips(token);
            if (data) {
                setTrips(data.data);
                setTripStats([
                    { label: 'Total', value: data.data.length || 0 },
                    { label: 'Today', value: data.statistics.totalTripsToday || 0 },
                    { label: 'This week', value: data.statistics.totalTripsThisWeek || 0 },
                    { label: 'This Month', value: data.statistics.totalTripsThisMonth || 0 },
                ]);
                console.log("Some shit: ", [
                  { label: 'Total', value: data.data.length || 0 },
                  { label: 'Today', value: data.statistics.totalTripsToday || 0 },
                  { label: 'This week', value: data.statistics.totalTripsThisWeek || 0 },
                  { label: 'This Month', value: data.statistics.totalTripsThisMonth || 0 },
                ])
                setLoading(false);
            } else {
                setAlert({
                    status: true,
                    message: "We are having trouble fetching trips data. Try again.",
                    type: 'error',
                });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            sendSentryAlert(error);
            setAlert({
            status: true,
            message: 'We are having trouble fetching trips data. Try again.',
            type: 'error',
            });
        }
    };

    fetchData();
  }, []);


  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6">
          <Typography variant="h6" color="white">
            Trips
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {loading ? (
            <Typography>
              <Spinner />
            </Typography>
          ) : null }
        {alert.status ? (
            <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
            </div>
        ) : null }
            <TripsTable 
              trips={trips} 
              statistics={tripStats} 
            />
        </CardBody>
      </Card>
    </div>
  );
}

export default Trips;
