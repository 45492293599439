import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";

export function Footer({ brandName, brandLink, routes }) {
  const year = new Date().getFullYear();

  return (
    <footer className="py-2">
      <div className="flex w-full flex-wrap items-center justify-center gap-6 px-2 ">
        <Typography variant="small" className="font-bold text-inherit">
          &copy; {" Copyright "} {year}{" "}{brandName} | Built by <a href={brandLink}  target="_blank" className="transition-colors text-[#0377F8] font-bold">Ujuzi Code&trade; </a>
        </Typography>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  brandName: "Sideways Tours",
  brandLink: "https://ujuzicode.com",
  routes: [
    { name: "Ujuzicode Ltd", path: "https://ujuzicode.com/" },
  ],
};

Footer.propTypes = {
  brandName: PropTypes.string,
  brandLink: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

Footer.displayName = "/src/widgets/layout/footer.jsx";

export default Footer;
