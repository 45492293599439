import React, { useState } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateUserFormComponent from "@/widgets/components/pagecomponents/users/createUser";
import { createUserFunc } from "@/apis/dash";
import GeneralAlert from '@/widgets/alerts/general';
import { useNavigate } from 'react-router-dom';

export function CreateUser() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        mobileNumber: '',
        role: '',
        agencyId: '',
        departmentId: '',
    });

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '' });
        const token = localStorage.getItem('token');
        try {
            const usercreated = await createUserFunc(token, formData);
            if(usercreated){
              setLoading(false);
              setAlert({
                  status: true,
                  message: usercreated.details,
                  type: 'success',
              });
            }else{
              setLoading(false);
              setAlert({
                  status: true,
                  message: 'Failed to create user.',
                  type: 'error',
              });
            }
            
        } catch (error) {
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'Failed to create user.',
                type: 'error',
            });
        }
    };

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6 flex-col justify-between">
          <Typography variant="h6" color="white">
            Create User
          </Typography>
          <button onClick={() => navigate("/dashboard/users")} className="text-white underline">
            Back
        </button>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">        
          { alert.status ? (
            <div className="w-1/2 mx-auto">
              <GeneralAlert props={{ ...alert, setAlert }} />
            </div>
          ) : null }
            <CreateUserFormComponent
              formData={formData}
              loading={loading}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              handleSubmit={handleSubmit}
            />
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateUser;
