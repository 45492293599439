import React, { useState, useEffect } from "react";
import { Spinner, Typography } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";

const TripsTable = ({ trips, statistics }) => {
    const [filterTerms, setFilterTerms] = useState([]); 
    const [secondFilterTerms, setSecondFilterTerms] = useState([]);
    const [thirdFilterTerms, setThirdFilterTerms] = useState([]);
    const [fourthFilterTerms, setFourthFilterTerms] = useState([]);

    useEffect(() => {
      const filterStatus = () => {
        const statuses = [
          ...new Set(trips.map((b) =>b.trip_status )),
        ].filter(Boolean);
        setFilterTerms(statuses); 
      }
      const routeFilter= () => {
        const routes = [
          ...new Set(trips.map((b) => b.route_code)),
        ].filter(Boolean);
        setSecondFilterTerms(routes);
      }
      const sessionFilter= () => {
        const sessions = [
          ...new Set(trips.map((b) => b.session)),
        ].filter(Boolean);
        setThirdFilterTerms(sessions);
      }
      const clientFilter= () => {
        const clients = [
          ...new Set(trips.map((b) => b.client)),
        ].filter(Boolean);
        setFourthFilterTerms(clients);
      }
      filterStatus();
      routeFilter();
      sessionFilter();
      clientFilter();
    },[]);

    const headers = ["Trip Date", "Session","Route Code", "Client", "Status", "Bookings", "Dispatch Count"];
    const columns = ["trip_date", "session","route_code", "client", "trip_status", "tripBookings", "trip_dispatch_count"]

  
    return (
        <ReusableTableComponent
            headers={headers}
            columns={columns}
            data={trips}
            statistics={statistics}
            sortableColumns={[0, 1, 2, 3, 4, 5]}
            titleColumnIndex={0}
            searchEnabled={true}
            titleClickable={true}
            titleClickableUrl="/dashboard/trips"
            settingsColumn={false}
            hideCreateButton={true}
            // createEntityLink="/dashboard/trips/create"
            filterEnabled={true}
            filterLabel={"Status"}
            filterOptions={filterTerms}     
            // createEntityLink="/dashboard/trips/create"       
            secondFilterEnabled={true}
            secondFilterLabel={"Routes"}
            secondFilterOptions={secondFilterTerms}
            // createEntityLink="/dashboard/trips/create"       
            thirdFilterEnabled={true}
            thirdFilterLabel={"Sessions"}
            thirdFilterOptions={thirdFilterTerms}
            // createEntityLink="/dashboard/trips/create"       
            fourthFilterEnabled={true}
            fourthFilterLabel={"Clients"}
            fourthFilterOptions={fourthFilterTerms}
        />
    );
};


export default TripsTable;
