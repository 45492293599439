import React, {useState,useEffect} from "react";

// @components
import {
  Card,
  Input,
  Button,
  CardBody,
  CardHeader,
  Typography,
  Spinner
} from "@material-tailwind/react";

import { useParams } from "react-router-dom";
import GeneralAlert from '@/widgets/alerts/general';
import { fetchUserByActivationCode, activateDeactivateUser } from "@/apis/auth";


export function AccountCreationActivation() {
  const { code } = useParams();
  console.log("code:",code);
  const [user, setUser] = useState(null);
  const [indexNo, setIndexNo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  const handleInputChange= (e) => setIndexNo(e.target.value);

  const fetchData = async () => {
    try {     
      const user = await fetchUserByActivationCode(code);
      console.log("user", user);
      if (user.status) {
        setUser(user.res);
        setLoading(false);        
      } else {
        setAlert({
          status: true,
          message: user.res,
          type: 'error',
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: error.message,
        type: 'error',
      });
    }
  };

  const handleSubmit = async (action) => {
    try{
      if(!indexNo){
        setAlert({
          status: true,
          message: "Ensure you input your index number.",
          type: 'error',
        });
        setLoading(false);
      }
      const updateUser = await activateDeactivateUser(user.id, indexNo, action);
      if(updateUser.status){
        setLoading(false);
        setAlert({
          status: true,
          message: `Account Activated successfully for user :${user?.email}.`,
          type: 'success',
        });
      }else{
        setLoading(false);
        setAlert({
          status: true,
          message: updateUser.res,
          type: 'error',
        });      
      }

    }catch(e){
      setLoading(false);
      setAlert({
        status: true,
        message: e.message,
        type: 'error',
      });
    }
  }

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);  

    fetchData();
  }, [code]);

  if(loading){
    return(
      <div className="flex justify-center items-center h-screen">
        <Spinner size="6rem" />
      </div>
    )
  }

  return (
    <section className="px-8">      
      <div className="container mx-auto h-screen grid place-items-center">
      { alert.status ? (
        <div className="w-1/2 mx-auto">
          <GeneralAlert props={{ ...alert, setAlert }} />
        </div>
      ) : null }
        <Card
          shadow={false}
          className="md:px-24 md:py-14 py-8 border border-gray-300"
        >
          <CardHeader shadow={false} floated={false} className="">
            <div className="">
              <img
                className="h-60 w-full object-cover object-center"
                src="/img/SidewaysLogo.jpg"
                alt="Sideways Logo"
              />
            </div>
            <Typography
              variant="h1"
              color="blue-gray"
              className="mb-4 !text-3xl lg:text-4xl"
            >
              Activate User Account
            </Typography>
            <Typography className="!text-gray-600 text-[18px] font-normal md:max-w-sm">
              New user account registered in your organisation. Please verify the user details:
            </Typography>
            <Typography className="!text-gray-600 text-[18px] font-normal md:max-w-sm">
              Name: {user?.username}
            </Typography>
            <Typography className="!text-gray-600 text-[18px] font-normal md:max-w-sm">
              Email: {user?.email}
            </Typography>
            <Typography className="!text-gray-600 text-[18px] font-normal md:max-w-sm">
              Phone: {user?.mobileNumber}
            </Typography>
            <Typography className="!text-gray-600 text-[18px] font-normal md:max-w-sm">
              Index Number: {user?.index_no}
            </Typography>
          </CardHeader>
          <CardBody >
            <Input label="Index Number" name="indexNo" type="number" onChange={handleInputChange}/>    
            <div className="flex justify-between">
              <Button size="lg" color="green" fullWidth className="m-2" onClick={()=>handleSubmit('approve')}>
                Approve account 
              </Button>
              <Button size="lg" color="red" fullWidth className="m-2" onClick={()=>handleSubmit('discard')}>
                Discard User Account 
              </Button>
            </div>        
          </CardBody>
        </Card>
      </div>
    </section>
  );
}

