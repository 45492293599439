import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Input,
  Spinner,
  Checkbox,
} from "@material-tailwind/react";
import { ChevronUpDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { confrmDispatches, breakDispatchFunction } from "@/apis/trips";
import GeneralAlert from '@/widgets/alerts/general';
import { ConfirmBookingsModal } from "@/widgets/modals/confirmBookings"

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  
  const date = new Date(dateString);
  if (isNaN(date)) return dateString;
  
  return date.toLocaleString('en-GB', options).replace(',', '');
};


export const DispatchTableComponent = ({
  headers,
  columns,
  data,
  sortableColumns,
  searchEnabled,
  settingsColumn,
  id  ,
  fetchData
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDispatches, setSelectedDispatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [modalAlert, setModalAlert] = useState({ status: false, message: '', type: '' });
  const [vehicles, setVehicles] = useState([]);
  const rowsPerPage = 10;
  const navigate = useNavigate();

  const closeModal = () => setOpenConfirmDialog(false);
  const toggleDialog = () => {
    setOpenConfirmDialog(!isDialogOpen)
  }

  const handleOpenEdit = (record) => {
    navigate(`/dashboard/dispatch/${record.id}`);
  };

  const handleRowSelection = (bookingId) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.includes(bookingId);
      const newSelectedRows = isSelected
      ? prevSelectedRows.filter((rowId) => rowId !== bookingId)
      : (data.find((row) => row.id === bookingId)?.is_confirmed === false 
          ? [...prevSelectedRows, bookingId]
          : prevSelectedRows);
  
  
      // Update the selected dispatches in the parent component
      if (setSelectedDispatches) {
        const selectedData = data.filter((item) => newSelectedRows.includes(item.id));
        setSelectedDispatches(selectedData);
      }
  
      return newSelectedRows;
    });
  };
  

  const handleSelectAll = () => {
    const allSelected = selectedRows.length === data.length;
    const newSelectedRows = allSelected ? [] : data.filter((row) => row.is_confirmed === false).map((row) => row.id);


    setSelectedRows(newSelectedRows);

    // Update the selected dispatches in the parent component
    if (setSelectedDispatches) {
      const selectedData = allSelected ? [] : data;
      setSelectedDispatches(selectedData);
    }
  };
  
  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleRemoveDispatch = (dispatchIdToRemove) => {
    setSelectedDispatches(prev => prev.filter(dispatch => dispatch.id !== dispatchIdToRemove));
  };

  const sortedData = () => {
    if (!sortConfig.key) {
      return data;
    }
    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = sortedData().filter((row) =>
    Object.values(row).some(
      (value) => typeof value === "string" && value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleTripBreak = (oldDispatch, newDispatch) => {
    const replacerDispatch = {
      ...oldDispatch,
      brokenDispatch: newDispatch,
    };
    setSelectedDispatches(prevDispatches =>
      prevDispatches.map(dispatch =>
        dispatch.id === oldDispatch.id ? replacerDispatch : dispatch
      )
    );
  };

  const handleConfirmDispatches = async (e) => {
    try {
      const token = localStorage.getItem('token');

      const confirmTripDispatch = await confrmDispatches(token, selectedDispatches);
      if (confirmTripDispatch && confirmTripDispatch.status) {
        setAlert({
          status: true,
          message: confirmTripDispatch.res,
          type: "success"
        });
        setLoading(false);
        setSelectedDispatches([]);
        setOpenConfirmDialog(!isDialogOpen)
        fetchData()
      } else {
        setAlert({
          status: true,
          message: 'Failed to process request.',
          type: "error"
        });
      }
    } catch (error) {
      console.error("Error creating dispatch:", error);
      setAlert({
        status: true,
        message: error.message,
        type: "error"
      });
      setLoading(false);
      toggleDialog();
    }
  };

  const handleReverseBreak = (dispatch) => {
    
    const restoredDispatch = {
      ...dispatch,
      bookingsCount: dispatch.bookingsCount + dispatch.brokenDispatch.bookingsCount,
      brokenDispatch: undefined,
    };
    setSelectedDispatches(prevDispatches =>
      prevDispatches.map(d =>
        d.id === dispatch.id ? restoredDispatch : d
      )
    );
  };
  

  const autoBreak = async () => {
    try {
      const updatedDispatches = selectedDispatches.map(dispatch => {
        if (dispatch.bookingsCount > dispatch.vehicleCapacity) {
          let excessBookings = dispatch.bookingsCount - dispatch.vehicleCapacity;    
          let newDispatch = {
            bookingsCount: excessBookings,
            parentDispatchId: dispatch.id,
          };
  
          return {
            ...dispatch,
            bookingsCount: dispatch.vehicleCapacity,
            brokenDispatch: newDispatch 
          };
        }
        return dispatch;
      });
  
      setSelectedDispatches(updatedDispatches);
    } catch (error) {
      console.error(error);
      setLoading(false); 
    }
  };

  const handleSelectChange = (passed_dispatch, vehicle_id) =>{
    const updatedDispatches = selectedDispatches.map(dispatch => {
      if (passed_dispatch.id === dispatch.id) {
        return {
          ...dispatch,
          vehicle_id: vehicle_id
        };
      }
      return dispatch;
    });
    setSelectedDispatches(updatedDispatches);

  }
  const fetchVehiclesData = async () => {
    try {
      const token = localStorage.getItem('token');
      const records = await fetchUnassignedResources(token);
      if (records) {
  
        console.log("vehiclesvehiclesvehicles:", records)
        setVehicles(records.unallocatedVehicles);
      } else {
        setAlert({
          status: true,
          message: "Unable to fetch records.",
          type: 'error',
        });
      }
    } catch (error) {
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: 'We are having trouble fetching records.',
        type: 'error',
      });
    } finally {
      setLoading(false);
    } 
  };


  useEffect(() => {  
    fetchVehiclesData();
  }, [])
  

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  if(loading){
    <div className="">
      <Spinner />
    </div>
  }

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-2 flex items-center justify-between gap-8">
          <div>
            <div className="flex gap-4 mt-4">
              {searchEnabled && (
                <div className="w-full md:w-72">
                  <Input
                    label="Search"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              )}
            </div>
          </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              {selectedRows.length > 0 ? (
                <Button className="bg-green-500" onClick={toggleDialog}>
                  Confirm Dispatch
                </Button>
              ) : (
                <Button className="bg-gray-400" disabled>
                  Confirm Dispatch
                </Button>
              )}
            </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        {alert.status && <GeneralAlert props={{ ...alert, setAlert }} />}
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="p-4">
                <Checkbox
                  checked={selectedRows.length === data.length}
                  onChange={handleSelectAll}
                />
              </th>
              {headers.map((header, index) => (
                <th key={index} className="border-b p-4 text-left">
                  <div className="flex items-center">
                    <span>{header}</span>
                    {sortableColumns.includes(columns[index]) && (
                      <ChevronUpDownIcon
                        onClick={() => requestSort(columns[index])}
                        className="h-5 w-5 cursor-pointer"
                      />
                    )}
                  </div>
                </th>
              ))}
              {settingsColumn && <th className="border-b p-4">Confirmed</th>}
              {settingsColumn && <th className="border-b p-4">Settings</th>}
            </tr>
          </thead>
          <tbody>
            {paginatedData.length > 0 ? (
              paginatedData.map((row, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="p-4">
                  <Checkbox
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelection(row.id)}
                  />
                  </td>
                  {columns.map((col, colIndex) => (
                    <td key={colIndex} className="border-b p-4">
                      {typeof row[col] === "string" && row[col].match(/^\d{4}-\d{2}-\d{2}T/)
                        ? formatDate(row[col])
                        : row[col]}
                    </td>
                  ))}
                  {settingsColumn && (
                    <td className="border-b p-4">
                      {row.is_confirmed ? <Button color="green">True</Button> : <Button color="red">False</Button>}
                    </td>
                  )}
                  {settingsColumn && (
                    <td className="border-b p-4">
                      <Button onClick={() => handleOpenEdit(row)}>view</Button>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length + (settingsColumn ? 1 : 0)} className="text-center p-4">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-200 bg-gray-50 p-4">
        <Typography variant="small" color="blue-gray">
          Page {currentPage} of {totalPages}
        </Typography>
        <div className="flex gap-2">
          <Button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => prev - 1)}
          >
            Prev
          </Button>
          <Button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev) => prev + 1)}
          >
            Next
          </Button>
        </div>
      </CardFooter>
      { openConfirmDialog && selectedDispatches.length && (
        <ConfirmBookingsModal 
          className="w-full" 
          openConfirmDialog={openConfirmDialog}
          toggleDialog={closeModal}
          handleRemoveDispatch={handleRemoveDispatch}
          selectedDispatches={selectedDispatches}
          handleTripBreakFunc={handleTripBreak} 
          autoBreak={autoBreak}
          handleConfirmDispatches={handleConfirmDispatches}
          modalAlert={modalAlert}
          setModalAlert={setModalAlert}
          handleReverseBreak={handleReverseBreak}
          vehicles={vehicles}
          handleSelectChange={handleSelectChange}
        />
      )}
    </Card>    
  );
};

export default DispatchTableComponent;
