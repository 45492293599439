import React, { useState, useEffect } from 'react';
import Joi from 'joi';
import {
    Card,
    Input,
    Button,
    Typography,
    Select,
    Option,
    Spinner
} from "@material-tailwind/react";

const schema = Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }).required(),
    username: Joi.string().required(),
    mobileNumber: Joi.string().pattern(/^[0-9]{10,15}$/).required(),
    role: Joi.string().valid('CAPAPROVER', 'CAPADMIN').required(),
    index_no: Joi.number().optional()
});

export function CreateCAPUserFormComponent({
    formData,
    loading,
    handleInputChange,
    handleSelectChange,
    handleSubmit
}) {
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    const validateForm = () => {
        const { error } = schema.validate(formData, { abortEarly: false });
        if (!error) {
            setErrors({});
            setIsFormValid(true);
        } else {
            const validationErrors = {};
            error.details.forEach(detail => {
                validationErrors[detail.path[0]] = detail.message;
            });
            setErrors(validationErrors);
            setIsFormValid(false);
        }
    };

    useEffect(() => {
        validateForm();
    }, [formData]);

    return (
        <Card color="transparent" shadow={false}>
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Username
                        </Typography>
                        <Input
                            size="lg"
                            name="username"
                            value={formData.username}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                        {errors.username && (
                            <p className="text-red-500 text-sm mt-1">{errors.username}</p>
                        )}
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            User Email
                        </Typography>
                        <Input
                            size="lg"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                        {errors.email && (
                            <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                        )}
                    </div>                   
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Mobile Number
                        </Typography>
                        <Input
                            size="lg"
                            name="mobileNumber"
                            value={formData.mobileNumber}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                        {errors.mobileNumber && (
                            <p className="text-red-500 text-sm mt-1">{errors.mobileNumber}</p>
                        )}
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Role
                        </Typography>
                        <Select
                            size="lg"
                            name="role"
                            value={formData.role}
                            onChange={(value) => handleSelectChange('role', value)}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        >
                            <Option value="CAPADMIN">Admin</Option>
                            <Option value="CAPAPROVER">Approver</Option>
                        </Select>                  
                        {errors.role && (
                            <p className="text-red-500 text-sm mt-1">{errors.role}</p>
                        )}
                    </div> 
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Index Number
                        </Typography>
                        <Input
                            size="lg"
                            type="number"
                            name="index_no"
                            value={formData.index_no}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                        />                
                    </div>  
                    <div className="col-span-1">
                        <Button className="mt-6" fullWidth type="submit" disabled={!isFormValid || loading}>
                            {loading ? <Spinner size="small" /> : 'Create User'}
                        </Button>
                    </div>
                </div>               
            </form>
        </Card>
    );
}

export default CreateCAPUserFormComponent;
