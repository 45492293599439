// auth.js

import React, { createContext, useState, useEffect, useContext } from "react";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const sessionData = JSON.parse(localStorage.getItem("sessionData"));
    if (sessionData) {
      const { expiry, isAuthenticated: sessionAuth } = sessionData;
      if (expiry > Date.now() && sessionAuth) {
        setIsAuthenticated(sessionAuth);
      } else {
        localStorage.removeItem("sessionData");
      }
    }
  }, []);

  const login = (token, userDetails) => {
    setIsAuthenticated(true);
    localStorage.setItem("token", token);
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    const expiry = Date.now() + 24 * 60 * 60 * 1000;
    localStorage.setItem("sessionData", JSON.stringify({ isAuthenticated: true, expiry }));
  };
  

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);