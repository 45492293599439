import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import { fetchOrgBookings } from "@/apis/clients";

const OrgBookings = ({ id }) => {
  const [bookings, setBookings] = useState([]);
  const [bookingStats, setBookingsStats] = useState([]);
  const [filterTerms, setFilterTerms] = useState([]); // <-- State for agencies filter
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: "", type: "" });

  useEffect(() => {
    setAlert({ status: false, message: "", type: "" });
    setLoading(true);

    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const bookings = await fetchOrgBookings(token, id);

        if (bookings) {
          console.log("bookings: ", bookings);
          setBookings(bookings.bookings);

          // Create booking statistics
          setBookingsStats([
            { label: "Total", value: bookings.bookings.length || 0 },
            { label: "New", value: bookings.statistics.new || 0 },
            { label: "Active", value: bookings.statistics.active || 0 },
            { label: "Completed", value: bookings.statistics.completed || 0 },
            { label: "Cancelled", value: bookings.statistics.cancelled || 0 },
          ]);

          // Extract unique agencies for filter terms
          const agencies = [
            ...new Set(bookings.bookings.map((b) => b.agency)),
          ].filter(Boolean); // Filter out undefined/null agencies

          setFilterTerms(agencies); // Set unique agencies in state
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch bookings.",
            type: "error",
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: "We are having trouble fetching bookings.",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const headers = [
    "Status",
    "Booking Date",
    "Trip Date",
    "Passenger Name",
    "Passenger Email",
    "Agency",
    "Session",
    "Route Code",
    "Stage",
    "Dispatch Code",
    "Vehicle",
];

const columns = [
    "status",
    "bookingDate",
    "tripDate",
    "passengerName",
    "passengerEmail",
    "agency",
    "leg",
    "routeCode",
    "currentStage",
    "dispatchCode",
    "vehicle",
];


  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {alert.status && <GeneralAlert props={{ ...alert, setAlert }} />}
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        statistics={bookingStats}
        data={bookings || []}
        sortableColumns={[0, 1, 2]}
        titleColumnIndex={0}
        searchEnabled={true}
        titleClickable={false}
        filterEnabled={true}
        filterLabel={"Agency"}
        filterOptions={filterTerms}
        settingsColumn={false}
        hideCreateButton={false}
        createEntityLink="/dashboard/bookings/create"
      />
    </>
  );
};

export default OrgBookings;
