import React, { useState, useEffect } from 'react';
import {
  Card,
  Button,
  Typography,
  Spinner
} from "@material-tailwind/react";
import GeneralAlert from '@/widgets/alerts/general';
import { cancelTripFunction } from "@/apis/trips";
import sendSentryAlert from "@/utils/sentryUtil";

const allowedStatuses = [
  "NEW",
  "PENDING_DISPATCH",
  "DISPATCHED",
  "ACTIVE",
  "COMPLETED"
];

const isStatusActivated = (status) => {
  return allowedStatuses.includes(status) && status !== "CANCELLED";
};

export function ManageTrip({ trip, id }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [isActivated, setIsActivated] = useState(isStatusActivated(trip?.routeDetails?.trip_status));

  const handleCancelTrip = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const updateTripDetails = await cancelTripFunction(token, id);
      if (updateTripDetails) {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Trip updated successfully.',
          type: "success"
        });
        fetchData();
        setIsActivated(!isActivated);
      } else {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Failed to update trip.',
          type: "error"
        });
      }

    } catch (error) {
      console.log(error)
      setLoading(false);
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
        type: "error"
      });
    }
  };

  if(loading){
    return (
    <Spinner />
    )
  }

  return (
    <Card color="transparent" shadow={false}>
          {alert.status ? (
            <div className="w-1/2 m-6 ">
              <GeneralAlert props={{ ...alert, setAlert }} />
            </div>
          ) : null}
            <div className="m-4">
                <Typography variant="body1" color="blue-gray" className="mb-6">
                    Trip is currently {isActivated ? 'Active' : 'Inactive'}. <br /> This means {isActivated ? 'all' : 'no'} trip functionalities are available. Please make sure to review the trip details before making any changes.
                </Typography>
                { loading ? (
                    <Button color={"red"}>
                      <Spinner />
                    </Button>
                ) : (
                    <Button color={"red"} onClick={handleCancelTrip} disabled={!isActivated}>
                        Cancel Trip
                    </Button>
                )}
            </div>
    </Card>
  );
}

export default ManageTrip;
