import React from 'react';
import {
    Card,
    Input,
    Button,
    Typography,
    Spinner,
    Select,
    Option
} from "@material-tailwind/react";

export function CreateDriverContractFormComponent({
    formData,
    loading,
    handleInputChange,
    handleSelectChange,
    handleSubmit,    
}) {
    const today = new Date();

    return (
        <Card color="transparent" shadow={false}>
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Type
                        </Typography>
                        <Select
                            size="lg"
                            name="type"
                            value={formData.type}
                            onChange={(value) => handleSelectChange('type', value)}
                            required
                        >
                            <Option key="PARMANENT" value="PARMANENT">Long Term Contract <small>(parmanent)</small></Option>
                            <Option key="TEMPOR" value="TEMPOR">Short Term Contract <small>(temporary)</small></Option>
                        </Select>                  
                    </div>  
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Contract start Date.
                        </Typography>
                        <Input
                            size="lg"
                            name="start_date"
                            type="date"
                            min={today}
                            value={formData.start_date}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div> 
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Contract Expiry Date.
                        </Typography>
                        <Input
                            size="lg"
                            name="expiry_date"
                            type="date"
                            min={formData.start_date}
                            value={formData.expiry_date}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div> 
                    
                    <div className="col-span-1">
                        <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                            {loading ? <Spinner size="small" /> : 'Create Contract'}
                        </Button>
                    </div>
                </div>               
            </form>
        </Card>
    );
}

export default CreateDriverContractFormComponent;
