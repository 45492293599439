import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import FinancesPage from "@/widgets/components/pagecomponents/finances/finances";
import GeneralAlert from '@/widgets/alerts/general';

export function Finances() {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6">
          <Typography variant="h6" color="white">
          Finance
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {loading ? (
            <Typography>
              <Spinner />
            </Typography>
          ) : (
            alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : (
              <FinancesPage />
            )
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default Finances;
