import React, { useState } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateAgencyFormComponent from "@/widgets/components/pagecomponents/clients/createAgencyFormComponent";
import GeneralAlert from '@/widgets/alerts/general';
import  {createAgencyFunc}  from "@/apis/clients";
import { useNavigate, useParams } from 'react-router-dom';


export function CreateOrganisationAgency() {
    const { id } = useParams();
    const [clientData, setClientData] = useState({
        name: '',
    });
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '',type:'' });  

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClientData({ ...clientData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setClientData({ ...clientData, [name]: value });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '' });
        const token = localStorage.getItem('token');
        try {
            const createAgency = await createAgencyFunc(token, {...clientData, parentAgencyId: id,type: 'CAPACCOUNT',});
            if(createAgency){
                navigate(-1)
            }else{
                setLoading(false);
                setAlert({
                    status: true,
                    message: error.response?.data?.message || 'Failed to create Agency.',
                    type:"error"
                });
            }
            
        } catch (error) {
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
                type:"error"
            });
        }
    };

 

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="flex justify-between mb-8 p-6">
        <Typography variant="h6" color="white">
                Create Agency
            </Typography>
            <div className="flex items-center">
                <button onClick={() => navigate(-1)} className="text-white underline">
                    Back
                </button>
            </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
        {  alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null }
        <CreateAgencyFormComponent
          formData={clientData}
          loading={loading}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          handleSubmit={handleSubmit}
        />
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateOrganisationAgency;
