import React from 'react';
import {
    List,
    ListItem,
    ListItemSuffix,
    Chip,
    Card,
} from "@material-tailwind/react";

// Helper function to convert keys to human-readable format
const formatLabel = (label) => {
    if (label === 'atCapacity') return 'At Capacity';
    // if (label === 'otherTag') return 'Other Stat'; // Add more mappings as needed
    return label.charAt(0).toUpperCase() + label.slice(1).replace(/([A-Z])/g, ' $1'); // Default transformation
};

const ReusableListWithBadge = ({ items }) => {
    console.log("items: ", items);

    const firstColumn = items[0] || {}; // First object
    const secondColumn = items[1] || {}; // Second object

    // Convert objects to arrays of entries for easier mapping
    const firstColumnEntries = Object.entries(firstColumn);
    const secondColumnEntries = Object.entries(secondColumn);

    return (
        <div>
            <Card className="w-full m-2">
                <div className="flex">
                    <div className="w-1/2 p-2 m-3"> {/* First Column */}
                        <List>
                            {firstColumnEntries.map(([label, number], index) => (
                                <ListItem key={index}>
                                    {formatLabel(label)} {/* Transform label to human-readable format */}
                                    <ListItemSuffix>
                                        <Chip
                                            value={number || 0} // Show 0 if number is unavailable
                                            variant="ghost"
                                            size="sm"
                                            className="rounded-full"
                                        />
                                    </ListItemSuffix>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <div className="w-1/2 p-2 m-3"> {/* Second Column */}
                        <List>
                            {secondColumnEntries.map(([label, number], index) => (
                                <ListItem key={index}>
                                    {formatLabel(label)} {/* Transform label to human-readable format */}
                                    <ListItemSuffix>
                                        <Chip
                                            value={number || 0} // Show 0 if number is unavailable
                                            variant="ghost"
                                            size="sm"
                                            className="rounded-full"
                                        />
                                    </ListItemSuffix>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default ReusableListWithBadge;
