import React from "react";
const API_KEY = import.meta.env.VITE_GOOGLEMAPS_API_KEY;

const MapComponent = ({ setStart, setEnd, setDistance, waypoints }) => {
  // Convert waypoints array to the format required by the Google Maps Embed API
  const waypointsParam = waypoints.map(point => `${point.name}`).join('|') || [];

  const googleMapsEmbedUrl = waypointsParam.length 
    ? `https://www.google.com/maps/embed/v1/directions?key=${API_KEY}&origin=${setStart}&destination=${setEnd}&waypoints=${waypointsParam}`
    : `https://www.google.com/maps/embed/v1/directions?key=${API_KEY}&origin=${setStart}&destination=${setEnd}`;
    
  return (
    <div className="App">
      <div className="p-2 text-left">
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          From <u>{setStart}</u> To <u>{setEnd}</u>
        </p>
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Distance: {setDistance}
        </p>
      </div>
      <div style={{ width: "100%", height: "100%" }}>
        <iframe
          title="Google Maps Directions"
          width="100%"
          height="600"
          style={{ border: 0 }}
          src={googleMapsEmbedUrl}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default MapComponent;
