import { Routes, Route } from "react-router-dom";
import { Sidenav, DashboardNavbar, Footer } from "@/widgets/layout";
import routes from "@/routes";
import { useMaterialTailwindController } from "@/context";

// Define or import the renderRoutes function
const renderRoutes = (pages) => {
  return pages.map(({ path, element, users, children }) => {
      if (children?.length) {
        return (
          <Route key={path} path={path} element={element}>
            {renderRoutes(children)}
          </Route>
        );
      } else {
        return <Route key={path} path={path} element={element} />;
      }
  });
};

export function Dashboard() {
  const [controller] = useMaterialTailwindController();
  const { sidenavType } = controller;

  return (
    <div className="flex flex-col min-h-screen bg-blue-gray-50/50">
      <Sidenav
        routes={routes || []}
        brandImg={
          sidenavType === "dark"
            ? "https://sidewaystoursandcarhire.com/images/logo.png"
            : "https://sidewaystoursandcarhire.com/images/logo.png"
        }
      />
      <div className="flex flex-col flex-grow p-4 xl:ml-80">
        <DashboardNavbar />
        <div className="flex-grow">
          <Routes>
            {routes.map(
              ({ layout, pages }) =>
                (layout === "dashboard" || layout === "services")  &&
                renderRoutes(pages)
            )}
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
}

Dashboard.displayName = "/src/layout/dashboard.jsx";

export default Dashboard;
