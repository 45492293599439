// apis/auth.js
import axios from 'axios';
import sendSentryAlert from "@/utils/sentryUtil";
const API_URL = import.meta.env.VITE_API_URL;

export async function fetchTripReportsData(token, data){
    try {
        const response = await axios.post(`${API_URL}/reports/trips`, {...data},{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}
export async function fetchRouteBookingsReportsData(token, data){
    try {
        const response = await axios.post(`${API_URL}/reports/route-bookings`, {...data},{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

