import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateTripFormComponent from "@/widgets/components/pagecomponents/trips/createTrip";
import GeneralAlert from '@/widgets/alerts/general';
import { fetchRoutesData} from "@/apis/maintenances";
import { createTripFunc} from "@/apis/trips";
import { useNavigate } from 'react-router-dom';

export function CreateTripPageComponent() {
    const [tripData, setTripData] = useState({
        trip_code: '',
        routeId: '',
        start_date: '',
        recurrence:''
    });

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '',type:'' });
    const [routes, setRoutes] = useState([]);
    const navigate = useNavigate();
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTripData({ ...tripData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setTripData({ ...tripData, [name]: value });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '' });
        const token = localStorage.getItem('token');
        try {
            const createAgency = await createTripFunc(token, tripData);
            if(createAgency){
                setLoading(false);
                setAlert({
                    status: true,
                    message: 'Trip created successfully.',
                    type:"success"
                });
                setTripData({
                    trip_code: '',
                    routeId: '',
                    start_date: '',
                    recurrence:''
                })
            }else{
                setLoading(false);
                setAlert({
                    status: true,
                    message: error.response?.data?.message || 'Failed to create trip.',
                    type:"error"
                });
            }
            
        } catch (error) {
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
                type:"error"
            });
        }
    };

    useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const token = localStorage.getItem('token');
        const data = await fetchRoutesData(token);
        setRoutes(data.routes);
      } catch (error) {
        console.log(error)
          setLoading(false);
          sendSentryAlert(error);
          setAlert({
              status: true,
              message: 'We are having trouble fetching routes.',
              type: 'error',
            });
      }
    };
      fetchRoutes();
      }, []);

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
      <CardHeader variant="gradient" color="gray" className="mb-8 p-6 flex flex-col justify-between items-start">
            <Typography variant="h6" color="white">
                Create Trip
            </Typography>
            <div className="flex items-center">
                <button onClick={() => navigate(`/dashboard/trips`)} className="text-white underline">
                    Back
                </button>
            </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
        {  alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null }
        <CreateTripFormComponent
          formData={tripData}
          loading={loading}
          routes={routes}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          handleSubmit={handleSubmit}
        />
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateTripPageComponent;
