// src/utils/sentryUtil.js
import * as Sentry from "@sentry/react";

const sendSentryAlert = (message, level = 'error', additionalInfo = {}) => {
  Sentry.captureMessage(message, {
    level,
    ...additionalInfo,
  });
};

export default sendSentryAlert;
