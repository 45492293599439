import React, { useEffect, useState } from "react";
import {
  Spinner,
  Typography
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import BookingSummary from "@/widgets/charts/bookings-summary"
import CustomStatisticsComponent from "@/widgets/charts/trips-statistics"
import BarChartDashComponent from "@/widgets/components/pagecomponents/Dashboard/components/barChart"


export function AdminUserDashboard() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();



  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

 

  return (
    <div className="mb-4">
        <CustomStatisticsComponent />    
        <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-2">
        <BookingSummary />
        <BarChartDashComponent />
      </div>
    </div>
  );
}

export default AdminUserDashboard;
