import React from "react";
import { Typography } from "@material-tailwind/react";

export function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-blue-gray-50">
      <Typography variant="h5" className="text-blue-gray-700">
        404
      </Typography>
      <Typography variant="h6" className="font-bold text-blue-gray-500">
        Page not found
      </Typography>
    </div>
  );
}

export default NotFound;
