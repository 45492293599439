import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import { fetchTripBookings } from "@/apis/trips"
const BookingsDetailsComponent = ({ dispatch }) => {  
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '', type: '' });

 

    const headers = [  "Passenger Name", "Client","Passenger Phone", 'Booking Date', "Trip Date", "Stage"];
    const columns = [ "username", "account","mobileNumber", "bookingDate", "tripDate", "stage"];

   

  if (loading) {
    return <Spinner />;
  }

    return (
        <>      
            { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
            <ReusableTableComponent
                headers={headers}
                columns={columns}
                data={dispatch.bookings || []}
                sortableColumns={[0, 1, 2, ]}
                titleColumnIndex={0}
                searchEnabled={true}
                titleClickable={false}
                settingsColumn={false}
                hideCreateButton = {true}
            />
        </>
    );
};


export default BookingsDetailsComponent;
