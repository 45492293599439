import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateChargeFormComponent from '@/widgets/components/pagecomponents/finances/createChargeComponent';
import GeneralAlert from '@/widgets/alerts/general';
import  { createChargeFunc }  from "@/apis/maintenances";
import { useNavigate } from 'react-router-dom';

export function CreateChargePageComponent() {
    const [data, setData] = useState({
      vehicle_capacity: '',
      charge_per_km: '',
    });

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '',type: '',});  

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSelectChange = (name, value) => {
      setData({ ...data, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '',type: ''  });
        const token = localStorage.getItem('token');
        try {
            const createCharge = await createChargeFunc(token, data);
            if(createCharge){
              setLoading(false);
              navigate(`/dashboard/finance`);
            }else{
              setLoading(false);
              setAlert({
                  status: true,
                  message: 'Failed to create charge.',
                  type: 'error' 
              });
            }
        } catch (error) {
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'Failed to create charge.',
                type: 'error' 
            });
        }
    };

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="flex justify-between mb-8 p-6">
          <Typography variant="h6" color="white">
                Create Charge
            </Typography>
            <div className="flex items-center">
                <button onClick={() => navigate(`/dashboard/finance`)} className="text-white underline">
                    Back
                </button>
            </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
            { alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null }

            <CreateChargeFormComponent
                formData={data}
                loading={loading}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                handleSubmit={handleSubmit}
            />           
          
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateChargePageComponent;
