import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateBookingFormComponent from "@/widgets/components/pagecomponents/bookings/createBookingsFormComponent";
import GeneralAlert from '@/widgets/alerts/general';
import  { createBookingRecord, fetchookingResorcesApiFunc}  from "@/apis/bookings";
import { useNavigate } from 'react-router-dom';


export function CreateBooking() {
    const [bookingData, setBookingData] = useState({});
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '',type:'' });  
    const [bookingResources, setBookingResources] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBookingData({ ...bookingData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setBookingData({ ...bookingData, [name]: value });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '' });
        console.log("bookingData:", bookingData)
        const token = localStorage.getItem('token');
        try {
            let request_obj = {
              route_id:bookingData.routeId,
              stage_id:bookingData.stageId,
              user_id:bookingData.passengerId,
              trip_date:bookingData.trip_date,
            }
            const createBooking = await createBookingRecord(token, request_obj);
            if(createBooking){
              setLoading(false);
              setAlert({
                  status: true,
                  message: 'Booking created successfully.',
                  type:"success"
              });
              setBookingData({})
            }else{
              setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'Failed to create booking.',
                type:"error"
            });
            }
            
        } catch (error) {
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
                type:"error"
            });
        }
    };

    useEffect(() => {    
        const fetchData = async () => {
            try {        
              setLoading(true);
                const token = localStorage.getItem('token');
                const resourceData = await fetchookingResorcesApiFunc(token);
                if (resourceData) {
                  console.log("resourceData: ",resourceData);
                  setBookingResources(resourceData);          
                setLoading(false);
                } else {
                setAlert({
                    status: true,
                    message: "Unable to fetch resources.",
                    type: 'error',
                });
                setLoading(false);
                }
            } catch (error) {
            console.log(error)
                setLoading(false);
                sendSentryAlert(error);
                setAlert({
                    status: true,
                    message: 'We are having trouble fetching organisation.',
                    type: 'error',
                });
            }
        };        
        fetchData();
    }, []);

  if(loading){
    return (
      <Spinner />
    )
  }

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="flex justify-between mb-8 p-6">
        <Typography variant="h6" color="white">
            Place Booking
            </Typography>
            <div className="flex items-center">
                <button onClick={() => navigate(-1)} className="text-white underline">
                    Back
                </button>
            </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">

            { alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null }

          <CreateBookingFormComponent
            formData={bookingData}
            clients={bookingResources?.clients || []}
            routes={bookingResources?.routes  || []}
            loading={loading}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            handleSubmit={handleSubmit}
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateBooking;
