import axios from 'axios';
import sendSentryAlert from "@/utils/sentryUtil";
const API_URL = import.meta.env.VITE_API_URL;

export async function fetchUserTypes(token){
    try {
        const response = await axios.get(`${API_URL}/dash/auth/user-types`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
};

export async function fetchRoute(token, id) {
  try {
    
    const response = await axios.post(`${API_URL}/dash/auth/route-data`, { id: id }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 201) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}
export async function fetchUserData(token, id) {
  try {
    
    const response = await axios.post(`${API_URL}/dash/auth/user-data`, { id: id }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function createVehicles(token, vehicleData){
  try {
      const response = await axios.post(`${API_URL}/dash/maintenances/vehicle/create`, { ...vehicleData } ,{
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      if (response.status === 201) {
          return {
              status: true,
              res: response.data.details
            }
      } else {
        console.log(response)
          return {
            status: false,
            res: response.data.details
          }
      }
  } catch (error) {
      sendSentryAlert(error.message);
      return false;
  }
}

export async function fetchVehiclesData(token) {
  try {
    
    const response = await axios.get(`${API_URL}/dash/maintenances/vehicles`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function fetchAvailableVehicles(token) {
  try {
    
    const response = await axios.get(`${API_URL}/dash/maintenances/available-vehicles`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function updateVehicleFunction(token, vehicleData){
  try {
    const response = await axios.put(`${API_URL}/dash/maintenances/vehicle`, { ...vehicleData } ,{
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
} catch (error) {
    sendSentryAlert(error.message);
    return false;
}
}

export async function activateDeactivateVehicleFunction(token, id){
  try {
    const response = await axios.post(`${API_URL}/dash/maintenances/vehicle/activate-deactivate/${id}`,{},{
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
} catch (error) {
    sendSentryAlert(error.message);
    return false;
}
}

export async function fetchVehicleFunc(token,id){
  try {
    
    const response = await axios.get(`${API_URL}/dash/maintenances/vehicle/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function uploadBulkVehicles(token, file){
  try {
    
    const response = await axios.post(`${API_URL}/dash/maintenances/vehicles/bulk`, { file: file }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function fetchDriversData(token) {
  try {
    
    const response = await axios.get(`${API_URL}/dash/maintenances/drivers`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function fetchDriverDetails(token,id){
  try {
    
    const response = await axios.get(`${API_URL}/dash/maintenances/driver/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function updateDriverFunction(token, driverData){
  try {
    const response = await axios.put(`${API_URL}/dash/maintenances/driver`, { ...driverData } ,{
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
} catch (error) {
    sendSentryAlert(error.message);
    return false;
}
}



export async function activateDeactivateDriverFunction(token, id){
  try {
    const response = await axios.post(`${API_URL}/dash/maintenances/driver/activate-deactivate/${id}`,{},{
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
} catch (error) {
    sendSentryAlert(error.message);
    return false;
}
}
export async function revokeDriverContractFunction(token, driver_id){
  try {
      const response = await axios.post(`${API_URL}/dash/maintenances/driver-contract/revoke`,{ driver_id: driver_id },{
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      if (response.status === 200) {
          return true;
      } else {
          return false;
      }
  } catch (error) {
      sendSentryAlert(error.message);
      return false;
  }
}

export async function fetchAvailableDriversData(token) {
  try {
    console.log("Driver fetched")
    const response = await axios.get(`${API_URL}/dash/trips/available-drivers`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}
export async function fetchChargeChartData(token) {
  try {
    
    const response = await axios.get(`${API_URL}/dash/finances/charge-chart`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function fetchFuelChartData(token) {
  try {
    
    const response = await axios.get(`${API_URL}/dash/finances/charges`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function fetchRouteFunc(token, id) {
  try {
    
    const response = await axios.post(`${API_URL}/dash/maintenances/route`, { id: id }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function fetchStageDetailsFunc(token, id){
  try {
    
    const response = await axios.get(`${API_URL}/dash/maintenances/stage/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function fetchRoutesData(token) {
  try {
    
    const response = await axios.get(`${API_URL}/dash/maintenances/routes`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function fetchDriverContracts(token, id) {
  try {
    
    const response = await axios.get(`${API_URL}/dash/maintenances/driver-contracts/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    sendSentryAlert(error);
    console.log(error)
    return false;
  }
}

export async function createDriverContract(token, data){
  try {
    const response = await axios.post(`${API_URL}/dash/maintenances/driver-contract/create`, { ...data } ,{
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
  } catch (error) {
      sendSentryAlert(error.message);
      return false;
  }
}

export async function createPassengerContract(token, data){
  try {
    const response = await axios.post(`${API_URL}/dash/maintenances/passenger-contract/create`, { ...data } ,{
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
  } catch (error) {
      sendSentryAlert(error.message);
      return false;
  }
}

export async function createFuelChargeFunc(token, data){
  try {
      const response = await axios.post(`${API_URL}/dash/finances/charge-chart`, { ...data } ,{
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      if (response.status === 200) {
          return response.data;
      } else {
          return false;
      }
  } catch (error) {
      sendSentryAlert(error.message);
      return false;
  }
}

export async function createChargeFunc(token, data){
  try {
      const response = await axios.post(`${API_URL}/dash/finances/charges`, { ...data } ,{
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      if (response.status === 200) {
          return response.data;
      } else {
          return false;
      }
  } catch (error) {
      sendSentryAlert(error.message);
      return false;
  }
}

export async function updateChargeFunc(token,data){
    try {
      const response = await axios.put(`${API_URL}/dash/finances/charges`, { ...data } ,{
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      if (response.status === 200) {
          return response.data;
      } else {
          return false;
      }
  } catch (error) {
      sendSentryAlert(error.message);
      return false;
  }
}

export async function fetchChargeDetailsFunc(token,id){
  try {
    const response = await axios.get(`${API_URL}/dash/finances/charge/${id}`,{
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
} catch (error) {
    sendSentryAlert(error.message);
    return false;
}
}

export async function createRoutesFunction(token, data){
  try {
      const response = await axios.post(`${API_URL}/dash/maintenances/routes/create`, { ...data } ,{
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      if (response.status === 201) {
          return response.data;
      } else {
          return false;
      }
  } catch (error) {
      sendSentryAlert(error.message);
      return false;
  }
}

export async function updateRouteFunction(token, data){
  try {
    const response = await axios.put(`${API_URL}/dash/maintenances/routes/update`, { ...data } ,{
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (response.status === 200) {
        return response.data;
    } else {
        return false;
    }
} catch (error) {
    sendSentryAlert(error.message);
    return false;
}
}


export async function createStageFunc(token, data){
  try {
      const response = await axios.post(`${API_URL}/dash/maintenances/routes/stages/create`, { ...data } ,{
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      if (response.status === 201) {
          return true;
      } else {
          return false;
      }
  } catch (error) {
      sendSentryAlert(error.message);
      return false;
  }
}

export async function updateStageFunc(token,data){
  try {
    const response = await axios.put(`${API_URL}/dash/maintenances/stage`, { ...data } ,{
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (response.status === 201) {
        return true;
    } else {
        return false;
    }
} catch (error) {
    sendSentryAlert(error.message);
    return false;
}
}
export async function fetchRouteTrips(token, id){
  try {
      const response = await axios.post(`${API_URL}/dash/maintenances/routes/trips`,{ id:id }, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      
      if(response.status === 200) {
          return response.data;
      } else {
          return false;
      }
  } catch (error) {
      sendSentryAlert(error);
      return false;
  }
}

export async function fetchRouteBookings(token, id){
  try {
      const response = await axios.post(`${API_URL}/dash/maintenances/routes/bookings`,{ id:id }, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      
      if(response.status === 200) {
          return response.data;
      } else {
          return false;
      }
  } catch (error) {
      sendSentryAlert(error);
      return false;
  }
}

export async function fetchDriverSales(token, id){
  try {
      const response = await axios.post(`${API_URL}/dash/maintenances/driver/sales`,{ id:id }, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      
      if(response.status === 200) {
          return response.data;
      } else {
          return false;
      }
  } catch (error) {
      sendSentryAlert(error);
      return false;
  }
}

export async function fetchRouteStages(token, id){
  try {
      const response = await axios.post(`${API_URL}/dash/maintenances/routes/stages`,{ id:id }, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      
      if(response.status === 200) {
          return response.data;
      } else {
          return false;
      }
  } catch (error) {
      sendSentryAlert(error);
      return false;
  }
}
