import React, { useState, useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from "@/widgets/alerts/general";
import {fetchRoutesData} from "@/apis/maintenances";

const Routes = ({ statistics }) => {
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [filterTerms, setFilterTerms] = useState([]); 

  useEffect(() => {
    const getRoutes = async () => {
      try {
        const token = localStorage.getItem('token');
        const data = await fetchRoutesData(token);
        setRoutes(data.routes);
      } catch (error) {
        sendSentryAlert(error);
        setAlert({ status: true, message: 'Error fetching routes. Please try again later.', type: 'error' });
      } finally {
        setLoading(false);
      }
    };

    getRoutes();
  }, []);

  useEffect(() => {
    const routeTypeFilter= () => {
      const route_types = [
        ...new Set(routes?.map((b) => b.route_type)),
      ].filter(Boolean);
      setFilterTerms(route_types); 
    }
    routeTypeFilter();
  }, [routes]);

  const headers = ["Route Code", "Client","Route Type", "Estimate Length", "Stage Count", "Trips", "Date Created"];
  const columns = ["route_code", "client","route_type", "estimate_length", "stagesCount", "completedTripsCount", "created_at"];

  if(loading){
    return (
      <Spinner />
    )
  }

  return (
    <>
        { alert.status  &&  <GeneralAlert props={{ ...alert, setAlert }} /> }

        <ReusableTableComponent
          headers={headers}
          columns={columns}
          data={routes || []}
          statistics={statistics}
          sortableColumns={[0, 1, 2, 3, 4, 5]}
          titleColumnIndex={0}
          searchEnabled={true}
          titleClickable={true}
          titleClickableUrl="/dashboard/routes"
          settingsColumn={false}
          createEntityLink="/dashboard/routes/create"
          allowsBulkUpload={false}
          filterEnabled={true}
          filterLabel={"Route Type"}
          filterOptions={filterTerms}
        />
    </>
  );
};

export default Routes;
