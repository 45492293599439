import React from 'react';

const GeneralAlert = ({ props }) => {
  const { message, status, type, setAlert } = props;

  let alertClasses = 'rounded-md p-4 flex justify-between items-center ';
  let iconClasses = 'w-5 h-5 mr-2';

  if (type === 'error') {
    alertClasses += 'bg-red-100 text-red-700';
    iconClasses += 'text-red-500';
  } else if (type === 'warning') {
    alertClasses += 'bg-yellow-100 text-yellow-700';
    iconClasses += 'text-yellow-500';
  } else if (type === 'success') {
    alertClasses += 'bg-green-100 text-green-700';
    iconClasses += 'text-green-500';
  }else{
    alertClasses += 'bg-gray-100 text-gray-700';
    iconClasses += 'text-yellow-200';
  }

  const handleClose = () => {
    setAlert({
      status: false,
      message: '',
      type: ''
    });
  };

  return (
    <div className={alertClasses}>
      <div className="flex items-center">
        {type === 'error' && (
          <svg
            className={iconClasses}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1zM9 14a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H9zM12.464 1.464a3 3 0 0 1 4.072 4.072l-10 10A3 3 0 0 1 2.536 15.536l10-10z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {type === 'warning' && (
          <svg
            className={iconClasses}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h.01m-6.253 5.75l-3.5 2 1-4.286-3.5-2-3.5 2 1 4.286 3.5-2m7 0l3.5 2-1-4.286-3.5-2-3.5 2 1 4.286 3.5-2z"
            />
          </svg>
        )}
        {type === 'success' && (
          <svg
            className={iconClasses}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        )}
        <p>{message || '--'}</p>
      </div>
      <button
        onClick={handleClose}
        className="ml-4 text-gray-500 hover:text-gray-700"
      >
        <svg
          className="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
};

export default GeneralAlert;
