import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import sendSentryAlert from "@/utils/sentryUtil";
import {fetchDriversData} from "@/apis/maintenances";

const Drivers = () => {
  const headers = ["User Name", "Phone Number", "Email", "Account Status", "Date Joined"];
  const columns = ["username", "mobileNumber","email","status","created_at"]

  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    const getVehicles = async () => {
      try {
        const token = localStorage.getItem('token');
        const data = await fetchDriversData(token);
        setDrivers(data);
      } catch (error) {
        sendSentryAlert(error);
        setAlert({ status: true, message: 'Error fetching vehicles.', type: 'error' });
      } finally {
        setLoading(false);
      }
    };

    getVehicles();
  }, [])

  const renderRow = (user) => (
    <>
      <td>
        <Typography variant="body" color="blue-gray" className="font-semibold">
          {user.username}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {user.mobileNumber}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {user.email}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {user.contract_type || "-"}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {user.contract_status || "-"}
        </Typography>
      </td>      
    </>
  );  

  return (
    <ReusableTableComponent
      headers={headers}
      columns={columns}
      data={drivers || []}
      renderRow={renderRow}
      sortableColumns={[0,3]}
      titleColumnIndex={0}
      searchEnabled={true}
      titleClickable={true}
      titleClickableUrl="/dashboard/driver"
      settingsColumn={false}
      hideCreateButton={true}
    />
  );
};

export default Drivers;
