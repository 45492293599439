import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import { fetchOrganisationDepartment } from "@/apis/clients";
const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  
  const date = new Date(dateString);
  if (isNaN(date)) return dateString;
  
  return date.toLocaleString('en-GB', options).replace(',', '');
};

const OrgDepartments = ({ id }) => {
  const [departments, setDepartment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const departments = await fetchOrganisationDepartment(token, id);
        if (departments) {
            setDepartment(departments);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch departments.",
            type: 'error',
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching departments.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const headers = ["Name", "Staff members", "Date created"];
  const columns = ["name", "staff_count", 'created_at'];

  const renderRow = (department) => (
    <>
      <td>
        <Typography variant="body" color="blue-gray" className="font-semibold">
          {department.name}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {department.staff_count}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {formatDate(department.created_at)}
        </Typography>
      </td>
    </>
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={departments}
        renderRow={renderRow}
        sortableColumns={[0, 1, 2, 3, 4, 5]}
        titleColumnIndex={0}
        searchEnabled={true}
        titleClickable={false}
        settingsColumn={false}
        createEntityLink={`/dashboard/clients/create-department/${id}`}
      />
    </>
  );
};

OrgDepartments.propTypes = {
  id: PropTypes.string.isRequired,
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  handleSettingsClick: PropTypes.func.isRequired,
};

export default OrgDepartments;
