import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateRouteFormComponent from "@/widgets/components/pagecomponents/maintenances/createRouteFormComponent";
import GeneralAlert from '@/widgets/alerts/general';
import { createRoutesFunction, fetchAvailableVehicles } from "@/apis/maintenances";
import { fetchOrganisations } from "@/apis/clients";
import { GoogleMap, LoadScript, Marker, useJsApiLoader, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';

const API_URL = import.meta.env.VITE_MAPS_KEY;


export function CreateRoutePageComponent() {
  const [routesData, setRoutesData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '' });
  const [ vehicles, setVehicles ] = useState([]);
  const [ clients, setClients ] = useState([]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRoutesData({ ...routesData, [name]: value });
  };

  const handleSelectChange = (name, value) => {
    setRoutesData({ ...routesData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAlert({ status: false, message: '' });
    const token = localStorage.getItem('token');
    try {
      const createRoute = await createRoutesFunction(token, routesData);
      if(createRoute){
        setLoading(false);
        navigate(`/dashboard/maintenances`)
      }else{
        setLoading(false);
        setAlert({
          status: true,
          type: 'error',
          message: 'Unable to create route',
        });
      }
      
    } catch (error) {
      setLoading(false);
      setAlert({
        status: true,
        type: 'error',
        message: error.response?.data?.message || 'Failed to create Routes.',
      });
    }
  };

  useEffect(() => {
    const getVehicles = async () => {
      try {
        const token = localStorage.getItem('token');
        const data = await fetchAvailableVehicles(token);
        setVehicles(data);
      } catch (error) {
        sendSentryAlert(error);
        setAlert({ status: true, message: 'Error fetching vehicles.', type: 'error' });
      } finally {
        setLoading(false);
      }
    };

    const getClients = async () => {
      try {
        const token = localStorage.getItem('token');
        const data = await fetchOrganisations(token);
        setClients(data.data);
      } catch (error) {
        sendSentryAlert(error);
        setAlert({ status: true, message: 'Error fetching vehicles.', type: 'error' });
      } finally {
        setLoading(false);
      }
    };

    getVehicles();
    getClients();
  }, [])


  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6 flex justify-between">
          <Typography variant="h6" color="white">
            Create Route
          </Typography>
          <div className="flex items-center">
            <button onClick={() => navigate(`/dashboard/maintenances`)} className="text-white underline">
              Back
            </button>
          </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {loading ? (
            <Typography>
              <Spinner />
            </Typography>
          ) : null }
          {  alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null }
              <>   
              <CreateRouteFormComponent
                  formData={routesData}
                  loading={loading}
                  handleSelectChange={handleSelectChange}
                  handleInputChange={handleInputChange}
                  handleSubmit={handleSubmit}
                  vehicles={vehicles || []}
                  clients={clients || []}
                />             
                {/* {isLoaded && (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={initialCenter}
                    zoom={10}
                    onClick={(e) => handleMapClick(e, routesData.start_point_coordinates === '' ? 'start_point_coordinates' : 'end_point_coordinates')}
                  >
                    {routesData.start_point_coordinates && (
                      <Marker position={{ lat: parseFloat(routesData.start_point_coordinates.split(',')[0]), lng: parseFloat(routesData.start_point_coordinates.split(',')[1]) }} />
                    )}
                    {routesData.end_point_coordinates && (
                      <Marker position={{ lat: parseFloat(routesData.end_point_coordinates.split(',')[0]), lng: parseFloat(routesData.end_point_coordinates.split(',')[1]) }} />
                    )}
                    {directions && (
                      <DirectionsRenderer directions={directions} />
                    )}
                  </GoogleMap>
                )} */}
              </>
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateRoutePageComponent;
