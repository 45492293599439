import React, { useState } from "react";
import { Card, CardHeader, CardBody, Typography } from "@material-tailwind/react";
import CreateDriverContractFormComponent from '/src/widgets/components/pagecomponents/maintenances/createDriverContractFormComponent';
import GeneralAlert from '@/widgets/alerts/general';
import  { createDriverContract}  from "@/apis/maintenances";
import { useNavigate, useParams } from 'react-router-dom';

export function CreateDriverContractPageComponent() {
    const { id } = useParams();
    const [data, setData] = useState({
        type: '',
        start_date: '',
        expiry_date: '',
        driver_id: id
    });    

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '',type: '',});  

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setData({ ...data, [name]: value });
      };
  

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '',type: ''  });
        const token = localStorage.getItem('token');
        try {
            const createCharge = await createDriverContract(token, data);
            if(createCharge){
                setLoading(false);
                setAlert({
                    status: true,
                    message: 'Contract created successfully.',
                    type: 'success' 
                });
                setData({
                    type: '',
                    expiry_date: '',
                    start_date:'',
                    driver_id: id
                });
                }else{
                setLoading(false);
                setAlert({
                    status: true,
                    message: 'Failed to create Contract.',
                    type: 'error' 
                });
            }
        } catch (error) {
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'Failed to create Contract.',
                type: 'error' 
            });
        }
    };



  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6 flex justify-between">
            <Typography variant="h6" color="white">
                Create Driver Contract
            </Typography>
            <div className="">
                <button onClick={() => navigate(`/dashboard/driver/${id}`)} className="text-white underline">
                    Back
                </button>
            </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
            { alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null }

            <CreateDriverContractFormComponent
                formData={data}
                loading={loading}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                handleSubmit={handleSubmit}
            />           
          
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateDriverContractPageComponent;
