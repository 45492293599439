// apis/auth.js
import axios from 'axios';
import sendSentryAlert from "@/utils/sentryUtil";
const API_URL = import.meta.env.VITE_API_URL;

const login = async (props) => {
  const { email, password } = props;
  try {
    const response = await axios.post(`${API_URL}/dash/auth/login`, { email, password });
    if(response.status === 201) {
        return {
          ...response.data
        };
    }else{
        return {
          ...response.data
        };
    }
  } catch (error) {
    sendSentryAlert(err.message);
    return false;
  }
};

const resetPassword = async (props) => {
  const { email } = props;
  try {
    const response = await axios.post(`${API_URL}/dash/auth/reset-password`, { email });
    if(response.status === 201) {
        return true;
    }else{
        return {
          ...response.data
        };
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return {
        ...error.response.data
      };
    }
    sendSentryAlert(err.message);
    return false;
  }
};

const activateAccount = async (props) => {
  const { code } = props;
  try {
    const response = await axios.post(`${API_URL}/dash/auth/activate-account`, { code });
    if(response.status === 201) {
        return true;
    }else{
        return true
    }
  } catch (error) {
    sendSentryAlert(err.message);
    return false;
  }
};

export async function updateUserDetailsFunction(token, id, data) {
  try {
      const response = await axios.put(`${API_URL}/dash/auth/update-user`, 
      {
        id:id,
        ...data
      },{
        headers: {
            Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
          return response.data;
      } else {
          return false;
      }
  } catch (error) {
      console.error("Error updating user details:", error);
      return false;
  }
}

export async function fetchUserByActivationCode(code){
  try {
      const response = await axios.post(`${API_URL}/dash/auth/code-user/`,{code});
      if (response.status === 200) {
        let user = response.data.user;
        console.log("useruser: ",user);
          return {
            status: true,
            res:user
          }
      } else {
        return {
          status: false,
          res:response.details
        }
      }
  } catch (error) {
      console.error("Error fetching user by activation code:", error);
      return {
        status: false,
        res:response.error.message
      }
  }
}

export async function activateDeactivateUser(user_id,index_no, action){
  try {
      const response = await axios.post(`${API_URL}/dash/auth/action-user`,{user_id,index_no, action},);
      if (response.status === 200) {
        return {
          status: true,
          res:response.details
        }
      } else {
        return {
          status: false,
          res:response.details
        }
      }
  } catch (error) {
      console.error("Error fetching user by activation code:", error);
      return {
        status: false,
        res:error.message
      }
  }
}



export default {
  login,
  resetPassword,
  activateAccount,
  updateUserDetailsFunction
};
