import React, { useState, useEffect } from "react";
import {  Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import {fetchOrgTariffs}  from "@/apis/clients"


const OrgTariffs = ({ id }) => {
    const [tariffs, setTariffs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '', type: '' });

    useEffect(() => {
        setAlert({ status: false, message: '', type: '' });
        setLoading(true);
        const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            const users = await fetchOrgTariffs(token, id);
            if (users) {
                setTariffs(users)       
            } else {
            setAlert({
                status: true,
                message: "Unable to fetch tariffs.",
                type: 'error',
            });
            }
        } catch (error) {
            sendSentryAlert(error);
            setAlert({
            status: true,
            message: 'We are having trouble fetching tariffs.',
            type: 'error',
            });
        } finally {
            setLoading(false);
        }
        };

        fetchData();
    }, [id]);

    const headers = ["Fuel Cost (Ksh)", "Vehicle Capacity","Cost per KM","Date created"];
    const columns = ["fuel_cost", "vehicle_capacity", "charge_per_km","created_at"];

    if (loading) {
        return <Spinner />;
    }

    return (
        <>

            { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
            <ReusableTableComponent
                headers={headers}
                columns={columns}
                data={tariffs || []}
                sortableColumns={[0, 1, 2, 3, 4, 5]}
                titleColumnIndex={0}
                searchEnabled={true}
                titleClickable={false}
                settingsColumn={true}
                hideCreateButton={false}
                createEntityLink={`/dashboard/clients/tariff/create/${id}`}
            />
        </>
    );
};



export default OrgTariffs;
