import React, { useState } from 'react';
import { Card, Button, Typography, Spinner, Dialog, DialogBody, DialogFooter } from "@material-tailwind/react";
import GeneralAlert from '@/widgets/alerts/general';

export function SecuritySettings({ onSubmit, loading, alert, setAlert, user_id }) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(!openDialog);

  const HandleSubmitFunction = (e) => {
    handleOpenDialog();
    onSubmit(e);
  }

  return (
    <div className="w-full block md:flex lg:flex justify-between">
      {/* Image Section */}
      <Card color="transparent" shadow={false} className="mx-auto w-full md:w-1/2">
        <img
          className="rounded-lg w-3/4 object-cover object-center"
          src="https://i.pinimg.com/564x/a2/ab/1a/a2ab1a5de803448cb63fee88626b557a.jpg"
          alt="gallery-photo"
        />
      </Card>

      {/* Form Section */}
      <Card color="transparent" shadow={false} className="w-full md:w-1/2 mx-auto">
        {alert.status && (
          <div className="w-1/2 mx-auto">
            <GeneralAlert props={{ ...alert, setAlert }} />
          </div>
        )}
        
        <Typography variant="h4" color="blue-gray">
          Reset User Password
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
          User forgot password? No Problem.
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
          Click reset password to generate a new password for the user and email it to the user.
        </Typography>

        {loading ? (
          <Button className="mt-6" fullWidth disabled>
            <Spinner />
          </Button>
        ) : (
          <Button className="mt-6 w-[30%]" fullWidth onClick={handleOpenDialog}>
            Reset Password
          </Button>
        )}

        {/* Dialog Box */}
        <Dialog open={openDialog} handler={handleOpenDialog}>
          <DialogBody className="text-left font-bold">
            <Typography variant="h5" color="blue-gray">
              {user_id 
                ? "Are you sure you want to reset the user's password?" 
                : "Change your password?"}
            </Typography>
          </DialogBody>
          <DialogFooter className="flex justify-center">
            <Button 
              variant="text" 
              color="red" 
              onClick={handleOpenDialog} 
              className="mr-2"
            >
              Cancel
            </Button>
            <Button 
              variant="gradient" 
              color="green" 
              onClick={HandleSubmitFunction}
            >
              OK
            </Button>
          </DialogFooter>
        </Dialog>
      </Card>
    </div>
  );
}

export default SecuritySettings;
