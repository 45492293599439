import React, { useState, useEffect } from "react";
import {
  Card,
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
  Select,
  Option,
  Spinner,
} from "@material-tailwind/react";
import sendSentryAlert from "@/utils/sentryUtil";
import { format, subDays } from "date-fns";
import { DayPicker } from "react-day-picker";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import { fetchTripReportsData } from "@/apis/reports";
import GeneralAlert from '@/widgets/alerts/general';

const headers = [
  "Route",
  "Trip Date",
  "Session",
  "Vehicle",
  "Driver",
  "Client",
  "Vehicle Capacity",
  "Reservations",
  "No Shows",
  "On Board",
  "No Bookings",
  "Distance",
  "start time",
  "End time",
  "Cost/Km",
  "Total Trip Cost",
];

const columns = [
  "route",
  "trip_date",
  "leg",
  "vehicle",
  "driver",
  "client",
  "vehicle_capacity",
  "reservations",
  "no_shows",
  "on_board",
  "no_bookings",
  "distance_travelled",
  "","",
  "cost_per_km",
  "total_trip_cost",
];

function isEmpty(obj) {
  return Object.keys(obj).length < 1;
}

export function TripsReportComponent() {
  const [tripReport, setTripReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1)); // Start of the current month
  const [endDate, setEndDate] = useState(new Date()); // Today's date
  const [selectedSession, setSelectedSession] = useState(""); // Session state
  const [selectedRouteType, setSelectedRouteType] = useState(""); // Route type state
  const [selectedRoute, setSelectedRoute] = useState(""); // Route state
  const [routes, setRoutes] = useState([]);
  const [tripStats, setTripStats] = useState([
    { label: "Total trips", value: 0 },
    { label: "Total cost", value: 0 },
    { label: "Reservations", value: 0 },
    { label: "Onboard", value: 0 },
    { label: "No Show", value: 0 },
    { label: "No Booking", value: 0 },
  ]);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  const fetchTripsReport = async () => {
    setLoading(true);

    const variables = {
      session: selectedSession,
      routeType: selectedRouteType,
      routeId: selectedRoute,
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
    };

    try {
      const token = localStorage.getItem("token");
      const reportData = await fetchTripReportsData(token, variables);
      console.log(typeof reportData)
      if (!reportData) {
        setAlert({
          status: true,
          message: "Unable to fetch reports at this time. Try again.",
          type: 'error',
        });
      }


      const totalTrips = reportData.length;
      const totalCost = reportData.reduce((acc, trip) => acc + trip.total_trip_cost, 0);
      const reservations = reportData.reduce((acc, trip) => acc + trip.reservations, 0);
      const onboard = reportData.reduce((acc, trip) => acc + trip.on_board, 0);
      const noShow = reportData.reduce((acc, trip) => acc + trip.no_shows, 0);
      const noBooking = reportData.filter((trip) => trip.no_bookings === "Yes").length;
      setTripReport(reportData)
      setTripStats([
        { label: "Total trips", value: totalTrips },
        { label: "Total cost", value: totalCost },
        { label: "Reservations", value: reservations },
        { label: "Onboard", value: onboard },
        { label: "No Show", value: noShow },
        { label: "No Booking", value: noBooking },
      ]);
    } catch (error) {
      sendSentryAlert(error);
      console.log("error: ",error)
      setAlert({
        status: true,
        message: "Unable to fetch reports at this time. Try again.",
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTripsReport();
  }, [startDate, endDate, selectedSession, selectedRouteType, selectedRoute]);

  return (
    <Card className="h-full w-full my-2">
      <section className="my-2 flex w-full justify-end px-2">
        <div className="p-2 m-2">
          <Select label="Select Session" onChange={(e) => setSelectedSession(e)}>
            <Option value="INBOUND">Inbound</Option>
            <Option value="OUTBOUND">Outbound</Option>
            <Option value="SINGLE">Single (security)</Option>
          </Select>
        </div>

        <div className="p-2 m-2">
          <Select label="Select Route Type" onChange={(e) => setSelectedRouteType(e)}>
            <Option value="security">Security</Option>
            <Option value="regular">Regular</Option>
          </Select>
        </div>

        <div className="p-2 m-2">
          <Select label="Select Route" onChange={(e) => setSelectedRoute(e)}>
            {routes.length &&
              routes.map((route) => (
                <Option key={route.id} value={route.id}>
                  {route.name}
                </Option>
              ))}
          </Select>
        </div>

        <div className="p-3 m-2">
          <Popover placement="bottom">
            <PopoverHandler>
              <Input
                label="Start Date"
                value={startDate ? format(startDate, "PPP") : ""}
                readOnly
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                mode="single"
                selected={startDate}
                onSelect={setStartDate}
                showOutsideDays
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="p-3 m-2">
          <Popover placement="bottom">
            <PopoverHandler>
              <Input
                label="End Date"
                value={endDate ? format(endDate, "PPP") : ""}
                readOnly
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                mode="single"
                selected={endDate}
                onSelect={setEndDate}
                showOutsideDays
              />
            </PopoverContent>
          </Popover>
        </div>
      </section>

      <section>
        {alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null }
      </section>

      <div>
        {loading ? (
          <p className="flex justify-center p-5">
            <Spinner />
          </p>
        ) : (
          <ReusableTableComponent
            headers={headers}
            columns={columns}
            data={tripReport || []}
            sortableColumns={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            titleColumnIndex={0}
            statistics={tripStats}
            searchEnabled={true}
            titleClickable={false}
            settingsColumn={false}
            hideCreateButton={true}
          />
        )}
      </div>
    </Card>
  );
}

export default TripsReportComponent;
