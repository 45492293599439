import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input, Button, Typography, Spinner } from '@material-tailwind/react';
import authApi from '@/apis/auth';
import GeneralAlert from '@/widgets/alerts/general';
import sendSentryAlert from '@/utils/sentryUtil';

export function ActivateAccount() {
  const [formData, setFormData] = useState({ code: ''});
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);

  const handleInput = (e) => {
    setAlert({ status: false, message: '', type: '' });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const activateAccount = await authApi.activateAccount(formData);
      if (activateAccount) {  
        setLoading(false);
        setAlert({
          status: true,
          message: loginData.details || 'Your account has been activated. Login to continue.',
          type: 'success',
        }); 
      } else {
        setLoading(false);
        setAlert({
          status: true,
          message: loginData.details || 'Unable to activate your account at this time. Contact administrator',
          type: 'error',
        });
      }
    } catch (error) {
      setLoading(false);
      sendSentryAlert(error);
      setAlert({ status: true, message: 'System unable to handle request. Contact your administrator.', type: 'error' });
    }
  };

  return (
    <section className="m-8 flex gap-4">
      <div className="w-full lg:w-3/5 mt-24">
        <div className="text-center">
          <Typography variant="h2" className="font-bold mb-4">Welcome</Typography>
         
          { alert.status ? (
            <div className="w-1/2 mx-auto">
              <GeneralAlert props={{ ...alert, setAlert }} />
            </div>
          ) : null  }
          
            <Typography variant="paragraph" color="blue-gray" className="text-lg font-normal">Enter the 6 digit code sent to your email.</Typography>
          
          

        </div>

        <form className="mt-8 mb-2 mx-auto w-80 max-w-screen-lg lg:w-1/2" onSubmit={handleSignIn}>
          <div className="mb-1 flex flex-col gap-6">
            <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">Activation code</Typography>
            <Input
              size="lg"
              name="code"
              type="text"
              onChange={handleInput}
              required              
              className="!border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{ className: "before:content-none after:content-none" }}
            />
            
          </div>

          {loading ? (
            <Button className="mt-6" color="green" fullWidth variant="outlined"><Spinner /></Button>
          ) : (
            <Button type="submit" color="green" className="mt-6" fullWidth>Activate Account</Button>
          )}

          <div className="flex items-center justify-between gap-2 mt-6">           
            <Typography variant="paragraph" className="text-center text-blue-gray-500 font-medium mt-4">
              <Link to="/auth/sign-in" className="text-gray-900 ml-1">Back to login</Link>
            </Typography>
          </div>
        </form>
      </div>

      <div className="w-full lg:w-2/5 flex justify-center items-center">
        <img src="/img/SidewaysLogo.jpg" className="rounded-3xl" alt="Pattern" />
      </div>

    </section>
  );
}