import axios from 'axios';
import sendSentryAlert from "@/utils/sentryUtil";
const API_URL = import.meta.env.VITE_API_URL;

export async function fetchOrganisations(token){
    try {
        const response = await axios.get(`${API_URL}/dash/agencies`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            console.log("Response with orgs: ",response.data)
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
};

export async function fetchDepartments(token,id){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/organizations`,{ agencyId:id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        
        if(response.status === 200){
            return response.data;
        } else {
            return false;
        }

    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
}

export async function updateOrgFunction (token,id,data){
    try {
        const response = await axios.put(`${API_URL}/dash/agencies/update/${id}`,{ ...data }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
}

export async function activateDeactivateOrgFunction (token,id){
    try {
        const response = await axios.put(`${API_URL}/dash/agencies/activate-deactivate/${id}`, {},{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
}

export async function fetchOrganisation(token,id){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/id`,{ id:id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
};

export async function fetchSubOrganisation(token,id){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/id/sub`,{ id:id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
};

export async function fetchAgencyOrganisations(token,id){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/organisation`,{ id:id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
};

export async function fetchOrgBookings(token,id){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/bookings`,{ id:id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            console.log(response)
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
};

export async function fetchOrgPeople(token,id){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/people`,{ id:id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
};

export async function fetchOrgTariffs(token, id){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/tariffs`,{ id:id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
}

export async function fetchOrgAdmins(token,id){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/admins`,{ id:id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
};

export async function fetchOrganisationTrips(token, id){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/trips`,{ id:id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
}

export async function fetchRouteTrips(token, id){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/trips`,{ id:id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
}

export async function fetchOrganisationDepartment(token, id){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/departments/`,{ agencyId:id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
}

export async function createAgencyFunc(token, agencyData){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/create`, { ...agencyData } ,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}


export async function createOrgUserFunc(token, userData){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/create-user`, { ...userData } ,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 201) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function createOrgDepartmentFunc(token, departmentData){
    try {
        const response = await axios.post(`${API_URL}/dash/agencies/departments/create`, { ...departmentData } ,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 201) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function fetchSupercapOrganisations(token){
    try {
        const response = await axios.get(`${API_URL}/dash/agencies/super-cap`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            console.log("Response with orgs: ",response.data)
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
};
