import React, { useState, useEffect } from "react";
import {
  Card,
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Select,
  Option,
  Spinner,
} from "@material-tailwind/react";
import sendSentryAlert from "@/utils/sentryUtil"; // Ensure you have the Sentry utility set up
import { format, subDays } from "date-fns";
import { DayPicker } from "react-day-picker";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from '@/widgets/alerts/general';
import { fetchRouteBookingsReportsData } from "@/apis/reports"; // Make sure to implement this function

const headers = [
  "Route Code",
  "Complete Bookings",
  "Active Bookings",
  "No Show Bookings",
  "Cancelled Bookings",
  "No Code Bookings ",
];

const columns = [
  "route_code",
  "completed_bookings",
  "active_bookings",
  "no_show_bookings",
  "cancelled_bookings",
  "no_booking_bookings"
];

export function BookingsReportComponent() {
  const [bookingsReport, setBookingsReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1)); // Start of the current month
  const [endDate, setEndDate] = useState(new Date()); // Today's date // Default end date
  const [selectedSession, setSelectedSession] = useState(""); // Session state
  const [selectedRouteType, setSelectedRouteType] = useState(""); // Route type state
  const [selectedRoute, setSelectedRoute] = useState(""); // Route state
  const [routes, setRoutes] = useState([]); // Assuming you will fetch routes
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [stats, setStats] = useState([
    { label: "Total", value: 0 },
    { label: "Regular", value: 0 },
    { label: "Security", value: 0 }
  ]);
  // Fetch function for bookings report
  const fetchBookingsReport = async () => {
    setLoading(true);
    const variables = {
      session: selectedSession,
      routeType: selectedRouteType,
      routeId: selectedRoute,
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
    };

    try {
      const token = localStorage.getItem("token");
      const reportData = await fetchRouteBookingsReportsData(token, variables); // Implement this API call

      if (!reportData) {
        setAlert({
          status: true,
          message: "Unable to fetch reports at this time. Try again.",
          type: 'error',
        });
      } else {
        setBookingsReport(reportData);
        setStats([
          {label: "Total", value: reportData.length },
          {label: "Security", value: reportData.filter((route) => route.route_type.toLowerCase()  === "security").length },
          {label: "Regular", value: reportData.filter((route) => route.route_type.toLowerCase() === "regular").length },
        ])
      }
    } catch (error) {
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: "Unable to fetch reports at this time. Try again.",
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookingsReport();
  }, [startDate, endDate, selectedSession, selectedRouteType, selectedRoute]);

  return (
    <Card className="h-full w-full my-2">
      <section className="my-2 flex w-full justify-end px-2">    
        <div className="p-2 m-2">
          <Select label="Select Route Type" onChange={(e) => setSelectedRouteType(e)}>
            <Option value="security">Security</Option>
            <Option value="regular">Regular</Option>
          </Select>
        </div>    

        <div className="p-3 m-2">
          <Popover placement="bottom">
            <PopoverHandler>
              <Input
                label="Start Date"
                value={startDate ? format(startDate, "PPP") : ""}
                readOnly
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                mode="single"
                selected={startDate}
                onSelect={setStartDate}
                showOutsideDays
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="p-3 m-2">
          <Popover placement="bottom">
            <PopoverHandler>
              <Input
                label="End Date"
                value={endDate ? format(endDate, "PPP") : ""}
                readOnly
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                mode="single"
                selected={endDate}
                onSelect={setEndDate}
                showOutsideDays
              />
            </PopoverContent>
          </Popover>
        </div>
      </section>

      <section>
        {alert.status ? (
          <div className="w-1/2 mx-auto">
            <GeneralAlert props={{ ...alert, setAlert }} />
          </div>
        ) : null}
      </section>

      <div>
        {loading ? (
          <p className="flex justify-center p-5">
            <Spinner />
          </p>
        ) : (
          <ReusableTableComponent
            headers={headers}
            columns={columns}
            statistics={stats}
            data={bookingsReport || []}
            sortableColumns={[0, 1, 2, 3, 4, 5, 6]}
            titleColumnIndex={0}
            searchEnabled={true}
            titleClickable={false}
            settingsColumn={false}
            hideCreateButton={true}
          />
        )}
      </div>
    </Card>
  );
}

export default BookingsReportComponent;
