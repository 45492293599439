import React, { useState, useEffect } from "react";
import { DispatchStatusButtons } from "./DispatchStatusButtons";
import  MapWithDispatchMarkers  from "./MapWithDispatchMarkers";

const socket_url = import.meta.env.VITE_TRACK_DISPATCH_SOCKET_URL;
const API_KEY = import.meta.env.VITE_GOOGLEMAPS_API_KEY;

export function TrackDispatchPageComponent() {
  const [statusCounts, setStatusCounts] = useState({
    inactive: 0,
    dispatchedMoving: 0,
    dispatchedPickup: 0,
    cancelled: 0,
    completed: 0,
  });
  const [dispatches, setDispatches] = useState([]);

  // Updates the counts based on the statuses in the dispatch list
  const updateStatusCounts = (dispatchList) => {
    const statusSummary = {
      inactive: 0,
      dispatchedMoving: 0,
      dispatchedPickup: 0,
      cancelled: 0,
      completed: 0,
    };

    dispatchList?.forEach((dispatch) => {
      switch (dispatch.status) {        
        case "ACTIVE":
          statusSummary.dispatchedMoving++;
          break;
        case "DISPATCHED":
          statusSummary.dispatchedPickup++;
          break;        
        case "COMPLETED":
          statusSummary.completed++;
          break;
        default:
          break;
      }
    });

    setStatusCounts(statusSummary);
  };

  useEffect(() => {
    updateStatusCounts(dispatches);
  }, [dispatches]);

  useEffect(() => {
    const socket = new WebSocket(socket_url);

    socket.onopen = () => {
      console.log("WebSocket connection established.");
    };
    
    socket.onmessage = (event) => {
      const updatedDispatches = JSON.parse(event.data);
      setDispatches(updatedDispatches?.dispatchDataWithLocation || []);
    };
    
    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
    
    socket.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    return () => {
      socket.close();
    };
  }, [socket_url]);

  return (
    <div className="App">
      {/* Display status buttons */}
      <DispatchStatusButtons statusCounts={statusCounts} />      
        <MapWithDispatchMarkers 
          API_KEY={API_KEY} 
          dispatches={dispatches}
          className="w-full m-1 p-1"
        />      
    </div>
  );
}

export default TrackDispatchPageComponent;
