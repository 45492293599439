import React, { useEffect, useState } from "react";
import { Card, CardBody, Typography, Spinner } from "@material-tailwind/react";
import GeneralAlert from '@/widgets/alerts/general';
import { useParams, useNavigate } from 'react-router-dom';
import {
  CloudIcon,
  Cog8ToothIcon,
  PencilSquareIcon,
  ArrowLeftCircleIcon,
  CurrencyDollarIcon
} from "@heroicons/react/24/solid";
import DriverDetailsPageComponent from "@/widgets/components/pagecomponents/maintenances/driverDetailsPage";
import DriverSalesComponent from "@/widgets/components/pagecomponents/maintenances/drivreSalesComponent";
import DriverManagement from "@/widgets/components/pagecomponents/maintenances/driverManagementComponent";
import DriverContractsComponent from "@/widgets/components/pagecomponents/maintenances/driverContracts";
import { fetchDriverDetails } from "@/apis/maintenances";

export function DriverDetailsPage() {
  const [driver, setDriver] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [selectedNav, setSelectedNav] = useState('details');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const driver = await fetchDriverDetails(token, id);
        if (driver) {
            setDriver(driver);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch driver details at this time.",
            type: 'error',
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching driver data. Try again.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleNavClick = (navItem) => {
    setSelectedNav(navItem);
  };

  let content;
  switch (selectedNav) {
    case 'details':
      content = <div>Contract Details</div>;
      break;
    case 'sales':
      content = <div>Sales</div>;
      break;
    case 'contract':
      content = <div>contract</div>;
      break;
    case 'management':
      content = <div>Management</div>;
      break;
    default:
      content = null;
  }

  return (
    <div className="m-2">
      <div className="mb-4">
        <div className="p-2 text-left">
            <h6 className="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                Driver Details
            </h6>            
        </div>
      </div>
      <div className="mt-12 mb-8 flex flex-row gap-6">
        <div className="flex-shrink-0 w-38 bg-gray-100 py-4">
          { loading ? (
            <Typography>
              <Spinner />
            </Typography>
          ) : null }
          <div className="flex flex-col gap-2">
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg 'bg-white text-gray-700`}
              onClick={() => navigate('/dashboard/maintenances')}
            >
              <ArrowLeftCircleIcon className="h-5 w-5 mr-2" />
              Back
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'details' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('details')}
            >
              <CloudIcon className="h-5 w-5 mr-2" />
              Details
            </button>          
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'contract' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('contract')}
            >
                <PencilSquareIcon className="h-5 w-5 mr-2" />
                Contract
            </button> 
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'sales' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('sales')}
            >
                <CurrencyDollarIcon className="h-5 w-5 mr-2" />
                Sales
            </button>         
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'management' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('management')}
            >
                <Cog8ToothIcon className="h-5 w-5 mr-2" />
                Management
            </button>     
          </div>
        </div>
        <div className="flex-1">
          {alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ): null }
          { loading ? (
            <div>Loading . . .</div>
          ) : (
              <Card>
                <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                  {selectedNav === 'details' && (
                    <div className="p-4">
                      <DriverDetailsPageComponent driver={driver} />
                    </div>
                  )}
                  {selectedNav === 'sales' && (
                    <div className="p-4">
                      <DriverSalesComponent id={id} />
                    </div>
                  )}
                  {selectedNav === 'contract' && (
                    <div className="p-4">
                      <DriverContractsComponent id={id} />
                    </div>
                  )}
                  {selectedNav === 'management' && (
                    <div className="p-4">
                      <DriverManagement formData={driver} id={id} />
                    </div>
                  )}
                </CardBody>
              </Card>
          )}
        </div>
      </div>
    </div>
  );
}

export default DriverDetailsPage;
