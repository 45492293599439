import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import { fetchDriverContracts } from "@/apis/maintenances"
const DriverContractsComponent = ({ id }) => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const data = await fetchDriverContracts(token, id);
        if (data) {
          setContracts(data);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch contracts.",
            type: 'error',
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching contracts.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const headers = [ "Contract Type","Starts From", "Ends on","Status",];
  const columns = [ "type","start_date", "expiry_date", "status"];


  if (loading) {
    return <Spinner />;
  }

  return (
    <>      
      { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={ contracts || []}
        sortableColumns={[0, 1, 2, ]}
        titleColumnIndex={0}
        searchEnabled={true}
        titleClickable={false} 
        settingsColumn={false}
        hideCreateButton = {false}
        createEntityLink={`/dashboard/maintenance/create-contract/${id}`}
      />
    </>
  );
};

export default DriverContractsComponent;
