import React from 'react';
import {
    Card,
    Input,
    Button,
    Typography,
    Select,
    Option,
    Spinner
} from "@material-tailwind/react";

export function CreateChargeFormComponent({
    formData,
    charges,
    loading,
    handleSelectChange,
    handleInputChange,
    handleSubmit,
}) {
    return (
        <Card color="transparent" shadow={false}>
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Vehicle Capacity
                        </Typography>
                        <Select
                            size="lg"
                            value={formData.vehicle_capacity} 
                            onChange={(value) => {
                                handleSelectChange('vehicle_capacity', value); 
                            }}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required 
                        >
                            <Option value={4}>4 seater </Option>
                            <Option value={9}>9 seater </Option>
                            <Option value={14}>14 seater </Option>
                            <Option value={22}>22 seater </Option>
                        </Select>
                        {/* <Input
                            size="lg"
                            name="vehicle_capacity"
                            type="float"
                            min={0}
                            value={formData.vehicle_capacity}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        /> */}
                    </div>  
                    <div className="col-span-1">

                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Charge Per KM(Ksh) 
                        </Typography>

                        <Input
                            size="lg"
                            name="charge_per_km"
                            type="float"
                            min={0}
                            value={formData.charge_per_km}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />

                    </div> 
                    <div className="col-span-1">

                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Fuel Cost
                        </Typography>

                        <Select
                            size="lg"
                            onChange={(value) => {
                                handleSelectChange('chart_id', value); 
                            }}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required 
                        >
                            {charges?.map((client) => (
                                <Option key={client.id} value={client.id}>
                                    Ksh {client.fuel_charge}
                                </Option>
                            ))}
                        </Select>

                        </div> 
                    <div className="col-span-1">
                        <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                            {loading ? <Spinner size="small" /> : 'Create Tariff'}
                        </Button>
                    </div>
                </div>               
            </form>
        </Card>
    );
}

export default CreateChargeFormComponent;
