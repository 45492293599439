import React, { useEffect, useState, useRef } from "react";

export function MapWithDispatchMarkers({ dispatches, API_KEY }) {
  const [map, setMap] = useState(null);
  const markersRef = useRef({}); // Use ref to persist markers across renders

  // Create a custom icon with text and a background
  const createMarkerIcon = (statusText, callback) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = "https://maps.google.com/mapfiles/kml/shapes/cabs.png";

    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;

    img.onload = () => {
      const ctx = canvas.getContext("2d");

      // Draw a light gray background for visibility
      ctx.fillStyle = "#f0f0f0";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the car image on the canvas
      ctx.drawImage(img, 25, 10, 50, 50); 

      // Add the status text below the image
      ctx.font = "bold 12px Arial";
      ctx.fillStyle = "green";
      ctx.textAlign = "center";
      ctx.fillText(statusText, canvas.width / 2, 85); // Center the text

      const iconUrl = canvas.toDataURL();
      callback(iconUrl);
    };

    img.onerror = () => {
      console.error("Failed to load the marker image.");
      callback(null); // Handle error gracefully
    };
  };

  const initMap = () => {
    const mapElement = document.getElementById("map");
    if (!mapElement) {
      console.error("Map container not found");
      return;
    }

    try {
      const mapInstance = new window.google.maps.Map(mapElement, {
        zoom: 15,
        center: { lat: -1.2934084171687132, lng: 36.79102873006288 }, // Default center
      });

      setMap(mapInstance);
    } catch (error) {
      console.error("Error initializing map:", error);
    }
  };

  // Function to update markers
  const updateMarkers = () => {
    if (!map) return;

    // Remove old markers
    Object.values(markersRef.current).forEach((marker) => marker.setMap(null));
    markersRef.current = {}; // Clear the markers reference
    // Add new markers
    dispatches.forEach((dispatch) => {
      const { liveLocation, vehicle, status, id } = dispatch;
      

      if (liveLocation) {
        createMarkerIcon(status, (customIconUrl) => {
          const customIcon = {
            url: customIconUrl,
            scaledSize: new window.google.maps.Size(50, 50),
          };

          const marker = new window.google.maps.Marker({
            position: {
              lat: parseFloat(liveLocation.lat),
              lng: parseFloat(liveLocation.lng),
            },
            map,
            title: `Vehicle ${vehicle.registration}`,
            icon: customIcon,
          });

          // Store the marker by dispatch ID for later reference
          markersRef.current[id] = marker;
        });
      }
    });
  };

  // Load Google Maps script dynamically
  useEffect(() => {
    const loadScript = () => {
      const existingScript = document.getElementById("maps");

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
        script.id = "maps";
        script.async = true;
        script.onload = () => {
          console.log("Google Maps script loaded");
          initMap();
        };
        script.onerror = () => {
          console.error("Failed to load Google Maps script");
        };
        document.head.appendChild(script);
      } else {
        console.log("Google Maps script already loaded");
        initMap();
      }
    };

    if (!window.google) {
      loadScript();
    } else {
      initMap();
    }
  }, [API_KEY]);

  // Update markers whenever dispatches change
  useEffect(() => {
    updateMarkers();
  }, [dispatches, map]);

  return <div style={{ width: "100%", height: "600px" }} id="map"></div>;
}

export default MapWithDispatchMarkers;
