import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Dashboard, Auth } from "@/layouts";
import { useAuth } from "@/context/auth";

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      {/* Protected Dashboard Routes */}
      <Route
        path="/dashboard/*"
        element={
          isAuthenticated ? (
            <Dashboard />
          ) : (
            <Navigate to="/auth/sign-in" replace />
          )
        }
      />

      {/* Authentication Routes */}
      <Route
        path="/auth/*"
        element={
          isAuthenticated ? (
            <Navigate to="/dashboard/home" replace />
          ) : (
            <Auth />
          )
        }
      />

      {/* Default Redirect */}
      <Route path="*" element={<Navigate to="/dashboard/home" replace />} />
    </Routes>
  );
}

export default App;
