import React from 'react';
import {
    Card,
    Input,
    Button,
    Typography,
    Select,
    Option,
    Spinner
} from "@material-tailwind/react";

export function CreateTripFormComponent({
    formData,
    routes,
    loading,
    handleInputChange,
    handleSelectChange,
    handleSubmit
}) {
    return (
        <Card color="transparent" shadow={false}>
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Trip code
                        </Typography>
                        <Input
                            size="lg"
                            name="trip_code"
                            value={formData.trip_code}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Route
                        </Typography>
                        <Select
                            size="lg"
                            name="routeId"
                            value={formData.routeId}
                            onChange={(value) => handleSelectChange('routeId', value)}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        >
                            {routes && routes.map(route => (
                                <Option value={route.id}>{route.route_code} - From {route.start_point} to {route.end_point}</Option>
                            ))}
                            
                        </Select>                  
                    </div>  
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Recurrence 
                        </Typography>
                        <Select
                            size="lg"
                            name="recurrence"
                            value={formData.recurrence}
                            onChange={(value) => handleSelectChange('recurrence', value)}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        >
                            <Option value="ONCE">One</Option>
                            <Option value="DAILY">Daily</Option>
                            <Option value="WEEKLY">Weekly</Option>
                        </Select>                  
                    </div> 
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Start Date
                        </Typography>
                        <Input
                            size="lg"
                            name="start_date"
                            type="date"
                            min={new Date()}
                            value={formData.start_date}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>  
                    <div className="col-span-1">
                        <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                            {loading ? <Spinner size="small" /> : 'Create Trip'}
                        </Button>
                    </div>
                </div>               
            </form>
        </Card>
    );
}

export default CreateTripFormComponent;
