import React from "react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";

const TripDispatchComponent = ({ dispatch }) => {
    const headers = ["Dispatch Code",  "From", "To", "Bookings","Vehicle", "Status"];
    const columns = ["dispatchCode", "routeFrom","routeTo","bookingsCount" ,"vehicleRegistration","status"]
    return (
        <ReusableTableComponent
            headers={headers}
            columns={columns}
            data={dispatch}
            sortableColumns={[0,1, 2, 3, 4, 5,6]}
            titleColumnIndex={0}
            searchEnabled={true}
            titleClickable={true}
            titleClickableUrl="/dashboard/dispatch"
            settingsColumn={false}
            hideCreateButton={true}
        />
    );
};


export default TripDispatchComponent;
