import React, { useState, useEffect } from 'react';
import {
  Card,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Spinner
} from "@material-tailwind/react";
import GeneralAlert from '@/widgets/alerts/general';
import TripDispatchComponent from "./tripDispatchComponent"
import TripPassengerPoolComponent from "./tripPassengerPool";
import { fetchTripDispatch, fetchUnassignedBookings, fetchUnassignedResources } from "@/apis/trips"

export function TripDispatch({id}) {
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const [dispatch, setDispatch] = useState([]);
  const [pool, setPool] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [activeTab, setActiveTab] = useState("dispatch");

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const dispatchRecords = await fetchTripDispatch(token, id);
        if (dispatchRecords) {
          setDispatch(dispatchRecords);
          setLoading(false);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch Dispatch records at this time.",
            type: 'error',
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching Dispatch data. Try again.',
          type: 'error',
        });
      }
    };

    const fetchOtherData = async () => {
      try {
        const token = localStorage.getItem('token');
        const records = await fetchUnassignedResources(token);
        if (records) {
          setVehicles(records.unallocatedVehicles);
          setDrivers(records.unallocatedDrivers);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch records.",
            type: 'error',
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching records.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      } 
    };

    fetchData();
    fetchOtherData()
  }, []);

  return (
    <Card color="transparent" shadow={false}>
      <Tabs value={activeTab} onChange={(value) => setActiveTab(value)}>
        <TabsHeader>
          <Tab value="dispatch">Dispatch</Tab>
          <Tab></Tab>
        </TabsHeader>
        { loading ? (
          <Spinner />
        ):(
          <TabsBody>
            {alert.status ? (
              <div className="w-1/2 m-6 ">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null}
            <TabPanel value="dispatch">
              <TripDispatchComponent dispatch={dispatch} />
            </TabPanel>
          </TabsBody>
        )}
        
      </Tabs>
    </Card>
  );
}

export default TripDispatch;
