import { Button } from "@material-tailwind/react";

export function DispatchStatusButtons({ statusCounts }) {
  return (
    <div className="flex w-max gap-2 md:gap-6 lg:gap-6 my-4">
    <Button color="yellow">Dispatched: En-route to Pickup ({statusCounts.dispatchedPickup})</Button>
      <Button color="green">Dispatched: On the move ({statusCounts.dispatchedMoving})</Button>
      <Button color="purple">Completed ({statusCounts.completed})</Button>
    </div>
  );
}

export default DispatchStatusButtons;