
import { useState } from 'react'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import FuelChargeChartComponent from "@/widgets/components/pagecomponents/finances/fuelChargeChartComponent";
 
export function ChargesComponent() {
  const [activeCharge, setActiveCharge]= useState();
  return (
    <>
      <div classame="">

      </div>
      <FuelChargeChartComponent />
    </>
  );
}
export default ChargesComponent;
