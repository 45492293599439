import {
  HomeIcon,
  UsersIcon, 
  ServerStackIcon,
  RectangleStackIcon,
  BuildingStorefrontIcon,
  Cog6ToothIcon,
  QueueListIcon,
  RocketLaunchIcon,
  LightBulbIcon,
  MapPinIcon,
  FlagIcon,
  CurrencyDollarIcon
} from "@heroicons/react/24/solid";
import { Home,ManageFinanceItem, CreateOrganisationAgency,CreateTripPageComponent,CreateClientTariffPageComponent, StageDetailsComponent, DispatchInvoiceReports, CreateFuelChargePageComponent,Finances,BookingsPage,BookingDetails ,DispatchDetails,DispatchPageComponent, CreateDriverContractPageComponent, DriverDetailsPage,VehicleDetailsComponent, Profile, Users,TripDetails, RouteDetailsComponent,UserPage, ClientsPageComponent,CreateOrgDepartment, OrganisationDetails,Trips, Maintenances, NotFound, CreateStagePageComponent,CreateRoutePageComponent, CreateUser, CreateOrganisation, CreateVehiclePageComponent, CreateOrgUser,CreateChargePageComponent, TrackDispatchPageComponent, CreatePassengerContractPageComponent, CreateBooking } from "@/pages/dashboard";
import { SignIn, RequestResetPassword, ActivateAccount, AccountCreationActivation } from "@/pages/auth";

const icon = {
  className: "w-5 h-5 text-inherit",
};

export const routes = [
  {
    title: "General",
    layout: "dashboard",
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: "Dashboard",
        path: "/home",
        element: <Home />,
        users:'ALL'
      },{
        icon: <BuildingStorefrontIcon {...icon} />,
        name: "Clients",
        path: "/clients",
        element: <ClientsPageComponent />,
        users:'ALL'
      },{
        icon: <LightBulbIcon {...icon} />,
        name: "bookings",
        path: "/bookings",
        element: <BookingsPage />,
        users:'ALL'
      },{
        icon: <RocketLaunchIcon {...icon} />,
        name: "Trips",
        path: "/trips",
        element: <Trips />,
        users:'ADMINS'
      },
      {
        icon: <FlagIcon {...icon} />,
        name: "Dispatch",
        path: "/dispatch",
        element: <DispatchPageComponent />,
        users:'ADMINS'
      },
      {
        icon: <MapPinIcon {...icon} />,
        name: "Live",
        path: "/live",
        element: <TrackDispatchPageComponent />,
        users:'ADMINS'
      },
      {
        path: "/trips/create",
        element: <CreateTripPageComponent />
      },{
        path:"bookings/create",
        element:<CreateBooking />
      },{
        path: "/bookings/:id",
        element: <BookingDetails />
      },{
        path: "/trips/:id",
        element: <TripDetails />
      },{
        path: "/drivers/create",
        element: <CreateVehiclePageComponent />
      },{
        path: "/driver/:id",
        element: <DriverDetailsPage />
      },
      {
        path: "/charges/create",
        element: <CreateChargePageComponent />
      },
      {
        path: "/routes/create",
        element: <CreateRoutePageComponent />
      },{
        path:"/management/stage/:id",
        element: <StageDetailsComponent />
      },{
        path: "/maintenances/create-stop/:id",
        element: <CreateStagePageComponent />
      },
      {
        path: "/maintenance/create-contract/:id",
        element: <CreateDriverContractPageComponent />
      },
      {
        path: "/routes/:id",
        element: <RouteDetailsComponent />
      },
      {
        path: "/vehicle/:id",
        element: <VehicleDetailsComponent />
      },{
        path: "/fuel-charges/create",
        element: <CreateFuelChargePageComponent />
      },{
        icon: <QueueListIcon {...icon} />,
        name: "reports",
        path: "/reports",
        element: <DispatchInvoiceReports />,
        users:'ALL'
      },
      {
        path: "/dispatch/:id",
        element: <DispatchDetails />
      },
    ],
  },
  {
    title: "settings",
    layout: "services",
    pages: [
      {
        path: "/users/:id",
        element: <UserPage />
      },{
        icon: <UsersIcon {...icon} />,
        name: "Users",
        path: "/users",
        element: <Users />,
        users:'ADMINS'
      },{
        icon: <Cog6ToothIcon {...icon} />,
        name: "Maintenances",
        path: "/maintenances",
        element: <Maintenances />,
        users:'ADMINS'
      },{
        icon: <CurrencyDollarIcon {...icon} />,
        name: "finance",
        path: "/finance",
        element: <Finances />,
        users:'ALL'
      },{
        path: "/finance/manage/:id",
        element: <ManageFinanceItem />
      },{
        path: "/users/create",
        element: <CreateUser />
      },{
        path: "/user/create-contract/:id",
        element: <CreatePassengerContractPageComponent />
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/clients/:id",
        element: <OrganisationDetails />
      },
      {
        path: "/clients/create",
        element: <CreateOrganisation />
      },
      {
        path: "/clients/tariff/create/:id",
        element: <CreateClientTariffPageComponent />
      },
      {
        path: "/clients/create-user/:id",
        element: <CreateOrgUser />
      },
      {
        path: "/clients/:id/create-agency",
        element: <CreateOrganisationAgency />
      },
    ],
  },
  {
    title: "Reports",
    layout: "reports",
    pages: [
      {
        icon: <QueueListIcon {...icon} />,
        name: "reports",
        path: "/reports",
        element: <NotFound />,
        users:'ALL'
      }
    ]},
  {
    title: "auth pages",
    layout: "auth",
    pages: [
      {
        icon: <ServerStackIcon {...icon} />,
        name: "sign in",
        path: "/sign-in",
        element: <SignIn />,
      },
      {
        icon: <RectangleStackIcon {...icon} />,
        name: "reset-password",
        path: "/reset-password",
        element: <RequestResetPassword />,
      },
      {
        icon: <RectangleStackIcon {...icon} />,
        name: "Activate Account",
        path: "/activate-account",
        element: <ActivateAccount />,
      },
      {
        icon: <RectangleStackIcon {...icon} />,
        name: "ActivatePassenger Account",
        path: "/accountActivation/:code",
        element: <AccountCreationActivation />,
      }
    ],
  }
];

export default routes;

