import React, { useState, useEffect } from 'react';
import {
  Card,
  Input,
  Button,
  Typography,
  Select,
  Option,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel
} from "@material-tailwind/react";
import GeneralAlert from '@/widgets/alerts/general';
import { updateDriverFunction, activateDeactivateDriverFunction,fetchDriverDetails, revokeDriverContractFunction } from "@/apis/maintenances";
export function DriverManagement({ id }) {
  const [loading, setLoading] = useState(false);
  const [driverData, setDriverData] = useState({});
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [activeTab, setActiveTab] = useState("editDetails");
  const [isActivated, setIsActivated] = useState((driverData?.status === "ACTIVE") ? true : false);
  const [isContractActive, setIsContractActive] = useState(false);
  const [errors, setErrors] = useState({});
  const fetchData = async () => {
    try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const data = await fetchDriverDetails(token, id);
        if (data) {
            setDriverData(data);
        } else {
            setAlert({
                status: true,
                message: "Unable to fetch trips.",
                type: 'error',
            });
        }
    } catch (error) {
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: 'We are having trouble fetching trips.',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    fetchData();
  }, [id]);

  useEffect(() => {
    if (driverData?.driverContract) {
      const hasActiveContract = driverData.driverContract.some(
        (contract) => contract.status === "ACTIVE"
      );
      setIsContractActive(hasActiveContract);
    }
  }, [driverData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDriverData({ ...driverData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAlert({ status: false, message: '', type:'' });
    const token = localStorage.getItem('token');
    try {
      const updateDriverData = {
        id,
        username: driverData.username,
        mobileNumber: driverData.mobileNumber,
        email: driverData.email,
      };
  
      const updateDriver = await updateDriverFunction(token, updateDriverData);
      if (updateDriver) {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Driver updated successfully.',
          type: 'success',
        });
        fetchData();
      } else {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Failed to update driver.',
          type: 'error',
        });
      }
    } catch (error) {
      setLoading(false);
      setAlert({
        status: true,
        message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
        type: 'error',
      });
    }
  };

  const handleToggleActivation = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const updateVehicle = await activateDeactivateDriverFunction(token, id);
      if (updateVehicle) {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Driver updated successfully.',
          type: "success"
        });
        fetchData();
        setIsActivated(!isActivated);
      } else {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Failed to update driver.',
          type: "error"
        });
      }

    } catch (error) {
      setLoading(false);
      setAlert({
        status: true,
        message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
        type: "error"
      });
    }
  };

  const handleRevokeContract = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const updateVehicle = await revokeDriverContractFunction(token, id);
      if (updateVehicle) {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Driver updated successfully.',
          type: "success"
        });
        fetchData();
        setIsActivated(!isActivated);
      } else {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Failed to update driver.',
          type: "error"
        });
      }

    } catch (error) {
      setLoading(false);
      setAlert({
        status: true,
        message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
        type: "error"
      });
    }
  };

  return (
    <Card color="transparent" shadow={false}>
      <Tabs value={activeTab} onChange={(value) => setActiveTab(value)}>
        <TabsHeader>
          <Tab value="editDetails">Edit Details</Tab>
          <Tab value="contractUpdates">Contract</Tab>
          <Tab value="activateDeactivate">Activate/Deactivate</Tab>
        </TabsHeader>
          {alert.status ? (
            <div className="w-1/2 m-6 ">
              <GeneralAlert props={{ ...alert, setAlert }} />
            </div>
          ) : null}
        <TabsBody>
          <TabPanel value="editDetails">
            <form className="m-4" onSubmit={handleSubmit}>
              <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6"> 
              
                
                <div className="col-span-1">
                  <Typography variant="h6" color="blue-gray" className="mb-3">
                    User Name
                  </Typography>
                  <Input
                    size="lg"
                    name="username"
                    type="text"
                    value={driverData?.username}
                    onChange={handleInputChange}
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{ className: "before:content-none after:content-none" }}
                  />
                </div>
                <div className="col-span-1">
                  <Typography variant="h6" color="blue-gray" className="mb-3">
                    Email
                  </Typography>
                  <Input
                    size="lg"
                    name="email"
                    type="email"
                    value={driverData?.email}
                    onChange={handleInputChange}
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{ className: "before:content-none after:content-none" }}
                  />
                </div><div className="col-span-1">
                  <Typography variant="h6" color="blue-gray" className="mb-3">
                    Phone Number
                  </Typography>
                  <Input
                    size="lg"
                    name="mobileNumber"
                    type="text"
                    value={driverData?.mobileNumber}
                    onChange={handleInputChange}
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{ className: "before:content-none after:content-none" }}
                  />
                </div>
              <div className="col-span-1">
                <Button type="submit" size="lg" fullWidth >
                  {loading ? (
                    <Spinner color="white" size="sm" />
                  ) : (
                    'Update Driver'
                  )}
                </Button>
              </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel value="activateDeactivate">
            <div className="m-4">
              <Typography variant="body1" color="blue-gray" className="mb-6">
              Driver currenty {isActivated ? 'Active' : 'Inactive'}. This means {isActivated ? 'all' : 'no'} operations can be done by this driver.<br />Please make sure to review the Driver details before making any changes.
              </Typography>
              { loading ? (
                <Button>
                  <Spinner />
                </Button>
              ) : (
                <Button color={isActivated ? "red" : "green"} onClick={handleToggleActivation}>
                  {isActivated ? "Deactivate Driver" : "Activate Driver"}
                </Button>
              )}
            </div>
          </TabPanel>
          <TabPanel value="contractUpdates">
            <div className="m-4">
              <Typography variant="body1" color="blue-gray" className="mb-6">
                Driver is currently {isContractActive ? '' : 'not currently'} under contract. Please make sure to review the Driver details before making any changes.
              </Typography>
              { loading ? (
                <Button>
                  <Spinner />
                </Button>
              ) : (
                <Button color={ "red" } onClick={handleRevokeContract} disabled={!isContractActive} >
                  Terminate Contract
                </Button>
              )}
            </div>
          </TabPanel>
        </TabsBody>
      </Tabs>
    </Card>
  );
}

export default DriverManagement;
