import React from 'react';
import { Card } from "@material-tailwind/react";

export function BookingDetailsComponents({ bookingDetails }) {
    const formatDate = (dateString) => {
        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        };
        
        const date = new Date(dateString);
        if (isNaN(date)) return dateString;
        
        return date.toLocaleString('en-GB', options).replace(',', '');
      };
      
    return (
        <Card color="transparent" shadow={false}>       
            <section class="bg-white py-2 antialiased">
                <div class="space-y-2 p-4 w-full ">                
                    <dl class="flex items-center justify-between gap-4">
                        <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Booking Date:</dt>
                        <dd class="text-base font-medium text-gray-900 dark:text-white">{formatDate(bookingDetails?.booking_date) || '-'} </dd>
                    </dl>
                    <dl class="flex items-center justify-between gap-4">
                        <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Trip Date:</dt>
                        <dd class="text-base font-medium text-gray-900 dark:text-white">{formatDate(bookingDetails?.trip_date) || '-'} </dd>
                    </dl>
                    <dl class="flex items-center justify-between gap-4">
                        <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Route Length</dt>
                        <dd class="text-base font-medium text-gray-900 dark:text-white">{bookingDetails?.route_length || 0}</dd>
                    </dl>
                    <dl class="flex items-center justify-between gap-4">
                        <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Trip Cost</dt>
                        <dd class="text-base font-medium text-gray-500">Ksh {bookingDetails?.distance_traveled || 0}</dd>
                    </dl>
                    <dl class="flex items-center justify-between gap-4">
                        <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Booking Status</dt>
                        <dd class="text-base font-medium text-green-900 ">{bookingDetails?.booking_status?.toLowerCase() || "New"}</dd>
                    </dl>                
                    <dl class="flex items-center justify-between gap-4">
                        <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Route</dt>
                        <dd class="text-base font-medium text-gray-900 dark:text-white">{bookingDetails?.route?.start_point || '-'} To {bookingDetails?.route?.end_point || '-'}</dd>
                    </dl>
                    <dl class="flex items-center justify-between gap-4">
                        <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Stage</dt>
                        <dd class="text-base font-medium text-gray-500">{bookingDetails?.stage?.name || '-'}</dd>
                    </dl>
                </div>
            </section>
        </Card>
    );
}

export default BookingDetailsComponents;
