import axios from 'axios';
import sendSentryAlert from "@/utils/sentryUtil";
const API_URL = import.meta.env.VITE_API_URL;

export async function fetchDashboardData(token){
    try {
        const response = await axios.get(`${API_URL}/dash/data/admin`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};

export async function fetchSuperadminDashboardData(token){
    try {
        const response = await axios.get(`${API_URL}/dash/data/superadmin`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};
export async function fetchAccountsDashboardData(token){
    try {
        const response = await axios.get(`${API_URL}/dash/data/accounts`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};

export async function fetchDispatchDashboardData(token){
    try {
        const response = await axios.get(`${API_URL}/dash/data/dispatch`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};

export async function fetchUsers(token){
    try {
        const response = await axios.get(`${API_URL}/dash/data/users`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};

export async function createUserFunc(token, userData){
    try {
        const response = await axios.post(`${API_URL}/dash/auth/create-admin`, { ...userData } ,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function fetchUserTypes(token){
    try {
        const response = await axios.get(`${API_URL}/dash/auth/user-types`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};

export async function updateUserStatus(token, userId) {
    try {
        const response = await axios.post(`${API_URL}/dash/auth/activate-deactivate`, {
            id: userId
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};

export async function fetchUserDetails(token, userId) {
    try {
        const response = await axios.post(`${API_URL}/dash/data/user`, {
            id: userId
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};

export async function activateDeactivateUser (token,userId){
    try {
        const response = await axios.post(`${API_URL}/dash/auth/activate-deactivate`, {
            id: userId
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

