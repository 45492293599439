import React from "react";
import { Card, CardBody, Typography, IconButton } from "@material-tailwind/react";
import dayjs from "dayjs";

// Mock data for the last 30 days (excluding weekends)
const mockData = [
  {
    date: dayjs().subtract(0, "day"),
    tripCount: 5,
    bookings: 50,
    noShows: 3,
    completedBookings: 47,
  },
  {
    date: dayjs().subtract(1, "day"),
    tripCount: 4,
    bookings: 40,
    noShows: 1,
    completedBookings: 39,
  },
  // Add more mock data for each day up to 30 days ago
];

const TripSummaryCalendarComponent = () => {
  const currentDate = dayjs();

  // Generate the last 30 days, excluding weekends
  const last30Days = [];
  for (let i = 0; last30Days.length < 30; i++) {
    const date = currentDate.subtract(i, "day");
    if (date.day() !== 0 && date.day() !== 6) {
      const dayData = mockData.find((d) => d.date.isSame(date, "day"));
      last30Days.unshift({ date, ...dayData });
    }
  }

  const blankDays = currentDate.day() === 0 ? 6 : currentDate.day() - 1;

  return (
    <Card className="calendar-month bg-grey-200 border border-grey-200 overflow-auto">
      <CardBody className="p-4">
        <section className="calendar-month-header flex justify-between bg-white p-5">
          <Typography
            id="selected-month"
            className="calendar-month-header-selected-month text-2xl font-semibold"
          >
            Trip Summary over the Last One Month
          </Typography>

          <div className="calendar-month-header-selectors flex items-center justify-between w-20">
            <IconButton variant="text" size="sm" id="previous-month-selector">
              &lt;
            </IconButton>
            <Typography id="present-month-selector" variant="small">
              Today
            </Typography>
            <IconButton variant="text" size="sm" id="next-month-selector">
              &gt;
            </IconButton>
          </div>
        </section>

        <ol id="days-of-week" className="day-of-week grid grid-cols-5 bg-[#212121] pb-2 pt-3 text-white text-xl">
          {["Mon", "Tue", "Wed", "Thu", "Fri"].map((day) => (
            <li key={day} className="text-right border-r border-grey-100 pr-2">
              {day}
            </li>
          ))}
        </ol>

        <ol id="calendar-days" className="days-grid grid grid-cols-5 gap-[1px] border-t border-grey-100">
          {Array(blankDays)
            .fill(null)
            .map((_, index) => (
              <li
                key={`blank-${index}`}
                className="calendar-day relative bg-[#212121] text-grey-100 p-1 min-h-[100px]"
              ></li>
            ))}

          {last30Days.map((day, index) => (
            <li
              key={index}
              className="calendar-day relative bg-[#212121] text-grey-100 p-2 min-h-[150px]"
            >
              <span className="absolute right-1 top-1 text-white">
                {day.date.format("D")}
              </span>
              {day.tripCount !== undefined ? (
                <div className="flex flex-col gap-1 mt-6 text-white">
                  <Typography>Trips: {day.tripCount}</Typography>
                  <Typography>Bookings: {day.bookings}</Typography>
                  <Typography>No Shows: {day.noShows}</Typography>
                  <Typography>Completed: {day.completedBookings}</Typography>
                </div>
              ) : (
                <Typography variant="small" className="text-center text-grey-400">
                  No Data
                </Typography>
              )}
            </li>
          ))}
        </ol>
      </CardBody>
    </Card>
  );
};

export default TripSummaryCalendarComponent;
