import { useState } from 'react';
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Spinner
  } from "@material-tailwind/react";
  import { Link } from "react-router-dom";
  import authApi from '@/apis/auth';
  import GeneralAlert from '@/widgets/alerts/general';
  import sendSentryAlert from '@/utils/sentryUtil';
  
  
  export function RequestResetPassword() {
    const [ userData, setUserData ] = useState({
      email:''
    });
    const [alert, setAlert] = useState({ status: false, message: '', type: '' });
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
      setAlert({ status: false, message: '', type: '' });
      setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) =>{
      e.preventDefault();
      setLoading(true);
      try {
        const resetPassword = await authApi.resetPassword(userData);
        if (typeof resetPassword === 'object' && resetPassword !== null) {    
            setAlert({
              status: true,
              message: `${resetPassword.details}.`,
              type: 'error',
            });
            setLoading(false);           
        } else if(typeof resetPassword === 'boolean' && resetPassword) {
            setAlert({
              status: true,
              message: `Password Reset successful. An email with your new password was sent to ${userData.email}.`,
              type: 'success',
            });
            setLoading(false);
            setUserData({
              email:''
            })
        } else {
          setLoading(false);
          setAlert({
            status: true,
            message: 'Unable to precess your request. Please contact admin.',
            type: 'error',
          });
        }
      } catch (error) {
        setLoading(false);
        sendSentryAlert(error);
        setAlert({ status: true, message: 'System currently unable to handle your request. Contact your administrator.', type: 'error' });
      }
    }

    return (
      <section className="m-8 flex gap-4">
        <div className="w-full lg:w-2/5 flex justify-center items-center py-auto h-3/4">
        <img src="/img/SidewaysLogo.jpg" className="rounded-3xl" alt="Pattern" />
      </div>
        <div className="w-full lg:w-3/5 flex flex-col items-center justify-center">
          <div className="text-center">
            <Typography variant="h2" className="font-bold mb-4">Request password reset.</Typography>
            <Typography variant="paragraph" color="blue-gray" className="text-lg font-normal">A new password will be generated and sent to the email entered below.</Typography>
            <Typography variant="paragraph" color="blue-gray" className="text-lg font-normal">Ensure the email address used is correct.</Typography>
          </div>
          <div>
            {alert.status ? (
              <div className="w-2/3 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null }
          </div>
          <form className="mt-8 mb-2 mx-auto w-80 max-w-screen-lg lg:w-1/2" onSubmit={handleSubmit}>
            <div className="mb-1 flex flex-col gap-6">
              <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">
                Email
              </Typography>
              <Input
                size="lg"
                placeholder=""
                name="email"
                type="email"
                value={userData.email}
                onChange={handleInputChange}
                required
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />              
            </div>
            <Button className="mt-6" fullWidth type='submit' disabled={loading}>
                { loading ? <Spinner /> : 'Reset Password' }
            </Button>         
            <Typography variant="paragraph" className="text-center text-blue-gray-500 font-medium mt-4">
              Return to 
              <Link to="/auth/sign-in" className="text-gray-900 ml-1">Log in</Link>
            </Typography>
          </form>
  
        </div>
      </section>
    );
  }
  
  export default RequestResetPassword;
  