import React, { useState, useEffect } from 'react';
import {
  Input,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import { XMarkIcon, CogIcon } from "@heroicons/react/24/outline";
import { fetchUnassignedResources } from "@/apis/trips"

function isEmpty(obj) {
  return obj && Object.keys(obj).length === 0;
}



export function ConfirmBookingsModal({ openConfirmDialog, toggleDialog, selectedDispatches, handleRemoveDispatch, handleTripBreakFunc, autoBreak, handleConfirmDispatches, handleReverseBreak, setModalAlert, vehicles, handleSelectChange }) {
  const [loading, setLoading] = React.useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  const handleBreakTrip = async (dispatch) => {
    try{
      setLoading(true)
      const { bookingsCount, vehicleCapacity } = dispatch;
      if (bookingsCount <= vehicleCapacity) {
        setLoading(false);
        setModalAlert({
            status: true,
            message: 'This booking does not meet breaking criteria. Change vehicle serving route if you realy need to break it.',
            type:"error"
        });
        return;
      }    
      const excessBookings = bookingsCount - vehicleCapacity;    
      const newDispatch = {
        bookingsCount: excessBookings,
        parentDispatchId: dispatch.id
      };
      await handleTripBreakFunc(dispatch,newDispatch);   
      setLoading(false)

    }catch(error){
      setLoading(false);
      console.error('error', error);
      setModalAlert({ status: true, message: error.message, type: 'error' });
    }
    
  
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const records = await fetchUnassignedResources(token);
        if (records) {
          setVehicleData(records.unallocatedVehicles);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch records.",
            type: 'error',
          });
        }
      } catch (error) {
        console.log(error);
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching records.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Dialog open={openConfirmDialog} handler={toggleDialog} className="p-4 w-full overflow-auto">
        <DialogHeader className="relative m-0 block">
          <Typography variant="h4" color="blue-gray">
            Confirm Trip Dispatch.
          </Typography>
          <Typography className="mt-1 font-normal text-gray-600">
            Once you confirm selected trips, drivers and passengers will receive notifications with dispatch details.
          </Typography>
          <Typography variant="sm" className="mt-1 font-normal text-red-600">
            Note: <span className="font-bold text-sm">Dispatch with bookings exceeding the vehicle capacity will not be confirmed.</span>
          </Typography>
          <IconButton
            size="sm"
            variant="text"
            className="!absolute right-3.5 top-3.5 bg-black"
            onClick={toggleDialog}
          >
            <XMarkIcon className="h-4 w-4 stroke-2 text-white" />
          </IconButton>
        </DialogHeader>
        <DialogBody className="space-y-4 pb-6 overflow-y-auto max-h-[calc(100vh-200px)]">
          {selectedDispatches.map(dispatch => (
            <div>
                <div key={dispatch.id} className="flex items-center gap-4 w-full">
                    <div className="w-full">
                        <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-2 text-left font-medium"
                        >
                        Route: {dispatch.routeCode}
                        </Typography>
                    
                    </div>
                    <div className="w-full">
                        <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-2 text-left font-medium"
                        >
                        Reservations : {dispatch.bookingsCount}
                        </Typography>               
                    </div>
                    <div className="w-full">
                        <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-2 text-left font-medium"
                        >
                        Current Vehicle: {dispatch.vehicleRegistration} capacity: {dispatch.vehicleCapacity}
                        </Typography>                
                    </div>

                    <div className="w-full p-2 m-1">
                      <label className="block text-sm font-medium text-gray-700 mb-3">
                          Vehicle
                      </label>
                      <select
                        value={dispatch.vehicle_id}
                        onChange={(e) => handleSelectChange(dispatch, e.target.value)}
                        className="block w-full pl-3 pr-10 py-2 text-base !border-t-blue-gray-200 focus:!border-t-gray-900 border-t-blue-gray-900"
                      >
                          <option value="">Select Vehicle</option>
                          {vehicleData?.length && vehicleData.map(vehicle => (
                              <option key={vehicle.id} value={vehicle.id}>
                                  {vehicle.registration} - {vehicle.capacity}
                              </option>
                          ))}
                      </select>
                    </div>
                    <div className="w-full flex">
                    {dispatch.bookingsCount > dispatch.vehicleCapacity && !isEmpty(dispatch.brokenDispatch) ? (
                            <Button
                                size="sm"
                                variant="text"
                                className="bg-black text-white"
                                onClick={(e) => handleBreakTrip(dispatch)}
                            >
                                break
                            </Button>
                          ) : (
                            <Button
                            size="sm"
                            variant="text"
                            className="bg-black text-white"
                            disabled
                        >
                            break
                        </Button>
                        )} 
                        <Button
                            size="sm"
                            variant="text"
                            className="bg-red-500 text-white m-1"
                            onClick={(e) => handleRemoveDispatch(dispatch.id)}
                        >
                            X
                        </Button>
                    </div>           
                </div>
                {dispatch.brokenDispatch !== undefined && (
                    <div className="">
                      <div key={dispatch.id} className="flex items-center gap-4">
                        <div className="m-2">
                            <Typography
                              variant="small"
                              color="gray"
                              className="mb-2 text-left font-medium"
                            >
                              New Dispatch
                            </Typography>
                        </div>
                        <div className="m-2">
                            <Typography
                            variant="small"
                            color="gray"
                            className="mb-2 text-left font-medium"
                            >
                            Reservations : {dispatch.brokenDispatch?.bookingsCount}
                            </Typography>               
                        </div>
                        <div className="m-2">
                          <label className="block text-sm font-medium text-gray-700 mb-3">
                              Vehicle
                          </label>
                          <select
                              value={dispatch.vehicle_id}
                              onChange={(e) => handleSelectChange(dispatch.id, e.target.value)}
                              required
                              className="block w-full pl-3 pr-10 py-2 text-base !border-t-blue-gray-200 focus:!border-t-gray-900 border-t-blue-gray-900"
                          >
                              <option value="">Select Vehicle</option>
                              {vehicleData?.length && vehicleData.map(vehicle => (
                                  <option key={vehicle.id} value={vehicle.id}>
                                      {vehicle.registration} - {vehicle.capacity}
                                  </option>
                              ))}
                          </select>
                        </div>
                        <div className="m-2">
                          <Button
                              size="sm"
                              variant="text"
                              className="bg-black text-white"
                              onClick={(e) => handleReverseBreak(dispatch)}
                          >
                              Reverse Break
                          </Button>
                      </div>
                      </div>  
                      <div key={dispatch.id} className="flex items-center gap-4">
                        <div className="m-2">
                            <Typography
                              variant="small"
                              color="gray"
                              className="mb-2 text-left font-medium"
                            >
                              Stages Affected: {dispatch.brokenDispatch?.stages_affected?.map(stage=>stage) || '--not processed--'}
                            </Typography>
                        </div>
                      </div>                 
                    </div>
                  )} 
                <hr className="w-full"/>
            </div>           
          ))}
        <DialogFooter>
          <Button className="mx-2" color="red" onClick={toggleDialog}>
            Cancel
          </Button>
          <Button className="mx-2" color="red" onClick={autoBreak}>
            Auto Break
          </Button>
          <Button className="mx-2" color="green" onClick={handleConfirmDispatches}>
            Confirm Selected:{selectedDispatches.length}
          </Button>
        </DialogFooter>
        </DialogBody>        
      </Dialog>
    </>
  );
}
