import React from 'react';
import { Typography } from '@material-tailwind/react';

const TripsStatisticsComponent = ({ data }) => {
  // Helper function to get the color based on the difference
  const getComparisonColor = (difference) => {
    if (difference > 0) return 'text-green-500';
    if (difference < 0) return 'text-red-500';
    return 'text-gray-300';
  };

  return (
    <div className="mb-12 flex flex-col md:flex-row justify-between gap-6">
      {/* Statistics for Trips */}
      <div className="w-full md:w-3/4 text-white p-4 m-4 bg-gray-900 border-2 border-gray-800 shadow-lg rounded-lg">
        <div className="flex flex-col md:flex-row justify-between">
          {/* Today's Trips */}
          <div className="p-2">
            <Typography variant="small" color="gray-300">
              Today's trips
            </Typography>
            <Typography variant="lead" color="white">
              {data?.tripsToday || 0}
            </Typography>
          </div>
          {/* Yesterday's Trips */}
          <div className="p-2">
            <Typography variant="small" color="gray-300">
              Yesterday's trips
            </Typography>
            <Typography variant="lead" color="white">
              {data?.tripsYesterday?.tripsYesterday || 0}
            </Typography>
            <Typography
              variant="small"
              className={getComparisonColor(data?.tripsYesterday?.tripsYesterday - data?.tripsYesterday?.tripsLastWeek)}
            >
              {data?.tripsYesterday?.tripsYesterday - data?.tripsYesterday?.tripsLastWeek > 0
                ? `+${data?.tripsYesterday?.tripsYesterday - data?.tripsYesterday?.tripsLastWeek} more than same day last week`
                : data?.tripsYesterday?.tripsYesterday - data?.tripsYesterday?.tripsLastWeek < 0
                ? `${data?.tripsYesterday?.tripsLastWeek - data?.tripsYesterday?.tripsYesterday} less than same day last week`
                : `Same as previous day`}
            </Typography>
          </div>
          {/* Last Week's Trips */}
          <div className="p-2">
            <Typography variant="small" color="gray-300">
              Last week's trips
            </Typography>
            <Typography variant="lead" color="white">
              {data?.tripsLastWeek?.bookingsCurrentWeek || 0}
            </Typography>
            <Typography
              variant="small"
              className={getComparisonColor(data?.tripsLastWeek?.tripsCurrentWeek - data?.tripsLastWeek?.tripsLastWeek || 0)}
            >
              {data?.tripsLastWeek?.tripsCurrentWeek - data?.tripsLastWeek?.tripsLastWeek > 0
                ? `+${data?.tripsLastWeek?.tripsCurrentWeek - data?.tripsLastWeek?.tripsLastWeek} more than same day previous week`
                : data?.tripsLastWeek?.tripsCurrentWeek - data?.tripsLastWeek?.tripsLastWeek < 0 ? `-${data?.tripsLastWeek?.tripsLastWeek - data?.tripsLastWeek?.tripsCurrentWeek} less than same day previous week`
                : `Same as previous week`}
            </Typography>
          </div>
          {/* Last Month's Trips */}
          <div className="p-2">
            <Typography variant="small" color="gray-300">
              Last month's trips
            </Typography>
            <Typography variant="lead" color="white">
              {data?.tripsThisMonth?.tripsCurrentMonth || 0}
            </Typography>
            <Typography
              variant="small"
              className={getComparisonColor(data?.tripsThisMonth?.tripsCurrentMonth  - data?.tripsThisMonth?.tripsLastMonth )}
            >
              {data?.tripsThisMonth?.tripsCurrentMonth - data?.tripsThisMonth?.tripsLastMonth  > 0
                ? `+${data?.tripsThisMonth?.tripsCurrentMonth  - data?.tripsThisMonth?.tripsLastMonth } more than previous month`
                : data?.tripsThisMonth?.tripsCurrentMonth  - data?.tripsThisMonth?.tripsLastMonth  < 0
                ? `-${data?.tripsThisMonth?.tripsLastMonth  - data?.tripsThisMonth?.tripsCurrentMonth } less than previous month`
                : `Same as previous month`}
            </Typography>
          </div>
        </div>
      </div>

      {/* Statistics for Vehicles, Drivers, and Passengers */}
      <div className="w-full md:w-1/4 lg:w-1/4 p-4 m-4 bg-gray-900 text-white border-2 border-gray-800 shadow-lg rounded-lg">
        {/* Passengers */}
        <div className="w-full flex justify-between mb-2">
          <Typography variant="small" color="gray-300">
            Passengers
          </Typography>
          <Typography variant="lead" color="white">
            {data?.passengersCount || '-'}
          </Typography>
        </div>
        {/* Vehicles */}
        <div className="w-full flex justify-between mb-2">
          <Typography variant="small" color="gray-300">
            Vehicles
          </Typography>
          <Typography variant="lead" color="white">
            {data?.vehiclesCount || '-'}
          </Typography>
        </div>
        {/* Drivers */}
        <div className="w-full flex justify-between">
          <Typography variant="small" color="gray-300">
            Routes
          </Typography>
          <Typography variant="lead" color="white">
            {data?.routesCount || '-' }
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TripsStatisticsComponent;
