import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import { fetchRouteTrips } from "@/apis/maintenances";

const RouteTripsComponent = ({ id }) => {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const trips = await fetchRouteTrips(token, id);
        if (trips) {
          setTrips(trips);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch trips.",
            type: 'error',
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching trips.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const headers = ["Trip ID", "Status", "Pickup Time", "Driver", "Vehicle"];
  const columns = ["trip_code", "trip_status", "start_date", "driver", "vehicle"];

  const renderRow = (trip) => (
    <>
      <td>
        <Typography variant="body" color="blue-gray" className="font-semibold">
          {trip.trip_id}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {trip.status}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {trip.pickup_time}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {trip.pickup_address}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {trip.driver}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {trip.vehicle}
        </Typography>
      </td>
    </>
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <GeneralAlert props={{ ...alert, setAlert }} />
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={trips}
        renderRow={renderRow}
        sortableColumns={[0, 1, 2, 3, 4, 5]}
        titleColumnIndex={0}
        searchEnabled={true}
        titleClickable={false}
        settingsColumn={false}
        hideCreateButton = {true}
      />
    </>
  );
};

export default RouteTripsComponent;
